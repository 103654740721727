import * as React from "react";
import BaseLayout from "~/Layout/Main.Layout";
import dic from "~/data/dic";
import { Box, Divider, ButtonBase, Chip, Typography } from "@mui/material";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "~/theme/components/header";
import { useEffect } from "react";
import { getLectureView } from "~/modules/User/userCrud";
import { useState } from "react";
import { navigate } from "@reach/router";

const LastViewPage = () => {
  const matches = useMediaQuery("(max-width:480px)");
  const [{ list }, setList] = useState({});

  useEffect(() => {
    getLectureView((_e, res) => {
      setList(res?.data);
    });
  }, [setList]);

  return (
    <BaseLayout
      topFrame={<Header border center fullWidth title={dic.lastView} />}
      pageName={`${dic.lastView} `}
    >
      <Box
        sx={{
          minHeight: 100,
          px: {
            xl: 16,
            lg: 16,
            md: 16,
            sm: 2,
            xs: 2,
          },
          py: 6,
        }}
      >
        <Box>
          {_.map(list, (item) => (
            <React.Fragment key={item.id}>
              <ButtonBase
                onClick={() => navigate(`player/${item.lectureId}`)}
                sx={{
                  width: 1 / 1,
                  height: 65,
                  px: {
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 0,
                    xs: 0,
                  },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Chip
                  sx={{
                    color: "white",
                    minWidth: 100,
                    ...(!matches && {
                      fontWeight: "bold",
                      mx: 2,
                    }),
                    ...(matches && {
                      mx: 1,
                    }),
                  }}
                  color="primary"
                  label={item.mainName}
                />
                <Typography
                  sx={{
                    fontWeight: "bold",
                    ...(matches && {
                      fontSize: 12,
                    }),
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={{
                    ml: 2,
                    ...(matches && {
                      fontSize: 12,
                    }),
                  }}
                >
                  {item.description}
                </Typography>
                <Box sx={{ flex: 1 }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle1">{item.createDate}</Typography>
                  <Typography sx={{ ml: 1 }} variant="caption">
                    {item.time}
                  </Typography>
                </Box>
              </ButtonBase>
              <Divider sx={{ border: 1, borderColor: "text.primary" }} />
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </BaseLayout>
  );
};

export default LastViewPage;
