import * as React from "react";
import { Box, Divider, IconButton, styled, Typography } from "@mui/material";
import _ from "lodash";
//import dic from "~/data/dic";
//import DownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "@reach/router";

const Item = styled(Box)(({ theme }) => ({
  minHeight: 60,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
}));

const LectureList = ({ data }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {_.map(data?.lectureList, (item, index) => (
        <React.Fragment key={index}>
          <Link to={`/player/${item.id}`}>
            <Item component="div">
              <Box
                sx={{ maxWidth: 40 }}
                component={"img"}
                src="/icons/play.svg"
              />
              <Box
                sx={{
                  mx: 2,                 
                  flex:1,
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",                  
                }}
              >
                {item?.lectureName && (
                  <Typography sx={{ color: "text.primary" }} variant="caption">
                    {item.title}
                  </Typography>
                )}
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "inline-block",
                    color: "text.primary",
                  }}
                >
                  {item?.lectureName || item.title}
                </Typography>

                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: 11,
                    color: "text.primary",
                  }}
                  component={"span"}
                  variant="subtitle2"
                >
                  {item.verse}
                </Typography>
              </Box>
              
              <Typography
                sx={{ display: "block", mx: 2, color: "text.primary",}}
                variant="caption"
              >
                {item.duration}
              </Typography>
              {item?.files?.sound?.url && (
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  download={"monib"}
                  href={item?.files?.sound?.url}
                >
                  <IconButton onClick={(e) => e.stopPropagation()}>
                    <Box
                      sx={{ maxWidth: 20 }}
                      component={"img"}
                      src="/icons/download.svg"
                    />
                  </IconButton>
                </a>
              )}
            </Item>
          </Link>
          <Divider sx={{ width: 1 / 1 }} />
        </React.Fragment>
      ))}
    </Box>
  );
};

export default LectureList;
