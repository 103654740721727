import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Typography } from "@mui/material";
import BaseLayout from "~/Layout/Main.Layout";
import monibSrc from "~/assets/icons/monib.svg";
//import underConstructionsSrc from "~/assets/icons/underConstructions.svg";
import underConstructionsIconsSrc from "~/assets/icons/underConstructions-icons.png";

const UnderConstruction = () => {
  return (
    <BaseLayout>
      <Box
        sx={{
          minHeight: 500,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Box
            component={"img"}
            title="underConstruction logo"
            sx={{maxWidth:90,mb:2}}
            src={underConstructionsSrc}
          /> */}
           <Box
            component={"img"}
            title="underConstruction logo"
            sx={{maxWidth:220,mb:2}}
            src={underConstructionsIconsSrc}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CloseIcon fontSize="large" sx={{ mr: 3 }} />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Divider sx={{ my: 0.5, border: 0.5, borderColor: "grey.700" }} />
              <Typography
                component={"div"}
                variant="h6"
                sx={{
                  py: 1,
                  px: 4,
                  minHeight: 30,
                  bgcolor: "primary.main",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: 3,
                  border: 0,
                  mx: -2,
                  borderColor: "var(--border-color)",
                }}
              >
                این صفحه در حال آماده‌سازی است
              </Typography>
              <Divider sx={{ my: 0.5, border: 0.5, borderColor: "grey.700" }} />
            </Box>
            <CloseIcon fontSize="large" sx={{ ml: 3 }} />
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            از شکیبایی شما متشکریم / تیم پشتیبانی
          </Typography>
          <Box
            component={"img"}
            title="monib logo"
            src={monibSrc}
            sx={{ width: 40, ml: 1 }}
          />
        </Box>
      </Box>
    </BaseLayout>
  );
};

export default UnderConstruction;
