import {
  Container,
  Box,
  IconButton,
  ButtonBase,
  Typography,
} from "@mui/material";
import MonibAppBar from "~/components/app.bar";
import MainMenu from "~/components/main.menu";
import dic from "~/data/dic";
import Frame from "~/theme/components/frame";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileAppbar from "~/components/mobile.app.bar";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "@reach/router";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import _ from "lodash";
import { navigate } from "@reach/router";
import ElevationScroll from "~/theme/components/elevation.scroll";

const NavBar = ({ links, pageName, mode }) => {
  return (
    <Box
      sx={{
        display: "flex",
        py: 1,
        px: 2,
        alignItems: "center",
        position: "sticky",
        top: 0,
        backdropFilter: "blur(10px)",
        '-webkit-backdrop-filter':"blur(10px)",
        zIndex: 999,
        boxShadow: mode === "scroll" ? 1 : 0,
        background:
          mode === "scroll" ? "rgba(255,255,255, 0.7)" : " transparent",
      }}
    >
      <Link to={"/"}>
        <ButtonBase>
          <Box
            component={"img"}
            src={"/icons/monib-title.svg"}
            sx={{ width: 60 }}
          />
        </ButtonBase>
      </Link>
      <Box sx={{ flex: 1, px: 2, display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{ color: "text.primary" }}
          // separator={<MonibVerticalDivider sx={{ height: 20, mx: 2 }} />}
        >
          <Link to="/">
            <ButtonBase>
              <Typography
                variant="caption"
                component={"div"}
                color="inherit"
                sx={{ color: "text.primary" }}
              >
                {dic.homePage}
              </Typography>
            </ButtonBase>
          </Link>
          {_.map(links, (item, index) => (
            <Link key={index} to={item.link}>
              <ButtonBase sx={{ color: "text.primary" }}>
                <Typography
                  variant="caption"
                  component={"div"}
                  color="inherit"
                  sx={{ color: "text.primary" }}
                >
                  {item.title}
                </Typography>
              </ButtonBase>
            </Link>
          ))}
          {pageName && (
            <Typography
              variant="caption"
              sx={{ fontWeight: "bold", color: "text.primary" }}
              component={"div"}
            >
              {pageName}
            </Typography>
          )}
        </Breadcrumbs>
      </Box>
      <IconButton onClick={() => navigate(-1)}>
        <BackIcon sx={{ color: "black" }} />
      </IconButton>
    </Box>
  );
};

const MainLayout = ({ children, pageName, links, topFrame }) => {
  const matches = useMediaQuery("(max-width:480px)");

  return (
    <>
      {matches && (
        <ElevationScroll>
          <NavBar links={links} pageName={pageName} />
        </ElevationScroll>
      )}
      {matches && <MobileAppbar />}     
      <Container
        sx={{
          px: { xs: 1.5, lg: 2, md: 2, sm: 2 },
          mt: { xs: 2, lg: 0, md: 5, sm: 2 },
        }}
        maxWidth="lg"
      >
        {!matches && <MonibAppBar links={links} pageName={pageName} />}
        {matches && topFrame}

        <Frame
          sx={{
            mt: 2,
            position: "relative",
            mb: 2,
            ...(matches && { minHeight: "calc(100vh - 180px)" }),
          }}
        >
          <MainMenu isHome />
          {children}
        </Frame>
      </Container>
    </>
  );
};

export default MainLayout;
