import { getAuthToken } from "~/modules/Auth/authAction";

export default function setupAxios(axios) {
  axios.interceptors.request.use(
    async (config) => {
      // const {
      //   auth: { authToken },
      // } = store.getState();

      config.headers = {
        AppName: "monibWebApp",
        ApiCode: "N>LZB$*8;,nr(/]&9Va&P!.ur(&9Ucf6",
      };

      // if (config.url.includes("security/refresh")) {
      //   return config;
      // }

      const authToken = getAuthToken();
      
      if (authToken && authToken!=="undefined") {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },

    (err) => Promise.reject(err)
  );
}
