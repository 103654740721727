import * as requestFromServer from "./authCrud";
import {
  callingCodesSlice,
  authSlice,
  callTypes,
  initialAuthState,
} from "./authSlice";

export const AUTH_KEY = "monib-web-auth";

const { actions } = callingCodesSlice;

export const fetchCallingCodes = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCallingCodes()
    .then((response) => {
      const data = response.data;
      dispatch(actions.callingCodesFetched(data));
    })
    .catch((error) => {
      error.clientMessage = "Can't get calling codes data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLogin = (data) => (dispatch) => {
  return requestFromServer
    .loginAsync(data)
    .then((response) => {
      const authData = response.data;
      dispatch(authSlice.actions.authFetched(authData));
    })
    .catch((error) => {
      error.clientMessage = "Can't get calling codes data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchInfo = (dispatch) => {
  return requestFromServer
    .infoAsync()
    .then((response) => {
      const authData = response.data;
      dispatch(authSlice.actions.authFetched(authData));
    })
    .catch((error) => {
      error.clientMessage = "Can't get calling codes data";
      dispatch(
        authSlice.actions.catchError({ error, callType: callTypes.list })
      );
    });
};

export const fetchAuth = (authData) => (dispatch) => {
  localStorage.setItem(AUTH_KEY, authData?.accessToken);
  return dispatch(authSlice.actions.authFetched(authData));
};

export const getAuthToken = () => localStorage.getItem(AUTH_KEY);

export const authLogout = (dispatch) => {
  localStorage.clear(AUTH_KEY);
  dispatch(authSlice.actions.authFetched(initialAuthState));
};
