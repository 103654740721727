import dic from "~/data/dic";

const publicData = {
  link: "https://v2.monibapp.com/",
};

export const title = {
  1: dic.quran,
  3: dic.nhj,
  4: dic.sahifa_t,
  5: dic.sp_t,
};

export const titleText = {
  1: dic.texQuran,
  3: dic.textNhj,
  4: dic.textSahifa_t,
  5: dic.sp_t,
};


export const link = {
  1: "/content/quran/",
  3: "/content/nahj-al-balagha/",  
  4: "/content/sahifa/",
  5: "/content/sp/",
};

export default publicData;
