import Api from "~/core/helper/apiHelper";
const { default: urlcat } = require("urlcat");

export function getWisdomList({ page = 0, size = 24 }, callback) {
  return Api.get(urlcat("wisdom", { page, size }), callback);
}

export function getWisdom({ id }, callback) {
  return Api.get(`wisdom/${id}`, callback);
}
