import * as React from "react";
import _ from "lodash";
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  DialogActions,
  Button,
} from "@mui/material";
import { fetchPlayList } from "~/modules/PlayList/playListAction";
import { useDispatch, useSelector } from "react-redux";
import { alpha } from "@mui/material/styles";
import dic from "~/data/dic";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import {
  lectureToPlaylist,
  topicToPlaylist,
  wisdomToPlaylist,
} from "~/modules/PlayList/playListCrud";
import { navigate } from "@reach/router";
import ManagePlaylist from "~/Pages/Playlist/manage";
import AddIcon from "@mui/icons-material/Add";

const SelectedPlaylist = ({ show, lectureId, topicId, onClose, wisdomId }) => {
  const { userData } = useSelector(({ auth }) => auth);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [{ showManage, playlist }, setPlaylist] = React.useState({
    showManage: false,
  });

  const dispatch = useDispatch();
  React.useEffect(() => {
    async function fetchData() {
      dispatch(fetchPlayList);
    }
    fetchData();
  }, [dispatch]);

  const { list } = useSelector((state) => state.playlist);

  const submit = React.useCallback(() => {
    if (lectureId) {
      lectureToPlaylist({ playlistIds: selected, lectureId }, () => {
        setIsLoading(false);
        onClose();
      });
    } else if (topicId) {
      setIsLoading(true);
      if (topicId) {
        topicToPlaylist({ playlistIds: selected, topicId }, () => {
          setIsLoading(false);
          onClose();
        });
      }
    } else if (wisdomId) {
      wisdomToPlaylist({ playlistIds: selected, wisdomId }, () => {
        setIsLoading(false);
        onClose();
      });
    }
  }, [onClose, setIsLoading, selected, lectureId, topicId, wisdomId]);

  const handleSelected = React.useCallback(
    (id) => () => {
      if (_.find(selected, (v) => v.id === id)) {
        setSelected((prv) => _.filter(prv, (v) => v.id !== id));
      } else {
        setSelected((prv) => [...prv, { id }]);
      }
    },
    [setSelected, selected]
  );

  const isSelected = React.useCallback(
    (id) => _.find(selected, (v) => v.id === id),
    [selected]
  );

  React.useEffect(() => {
    setIsLoading(false);
    setSelected([]);
  }, [show, setSelected]);

  const handleManage = React.useCallback(
    ({ show, playlist }) =>
      (event) => {
        event?.stopPropagation();
        setPlaylist({ showManage: show, playlist });
      },
    [setPlaylist]
  );


  return (
    <>
      <ManagePlaylist
        show={showManage}
        playlist={playlist}
        onClose={handleManage({ show: false })}
      />
      <Dialog open={show} onClose={onClose}>
        <DialogContent>
          {userData?.userId ? (
            <Box sx={{ minWidth: 200, p: 0.5 }}>
              <Button
                onClick={handleManage({ show: true })}
                variant="contained"
                sx={{ mb: 2 }}
                endIcon={<AddIcon />}
              >
                {dic.addPlaylist}
              </Button>
              {_.map(list?.list, (item) => (
                <Box
                  onClick={handleSelected(item.id)}
                  sx={{
                    px: 2,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    minHeight: 40,
                    borderRadius: 4,
                    mb: 1,
                    border: "1px solid var(--border-color)",
                    ...(isSelected(item.id) && {
                      borderColor: "primary.main",
                      color: "primary.main",
                    }),
                    "&:hover": {
                      boxShadow: (theme) =>
                        `${alpha(
                          theme.palette.primary.main,
                          0.3
                        )} 0 0 0 0.2rem`,
                    },
                  }}
                  key={item.id}
                >
                  {isSelected(item.id) && (
                    <CheckIcon sx={{ mr: 2 }} color="primary" />
                  )}
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                p: 2,
                minHeight: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                  xl: "row",
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  mr: 2,
                  mb: { xs: 2, sm: 2, md: 0, lg: 0, xl: 0 },
                  textAlign: "center",
                }}
              >
                {dic.needToLogin}
              </Typography>
              <Button
                onClick={() => navigate("/profile")}
                sx={{ minWidth: 120 }}
                variant="contained"
              >
                {dic.login}
              </Button>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{dic.cancel}</Button>
          {userData?.userId && (
            <LoadingButton
              loading={isLoading}
              onClick={submit}
              variant="contained"
            >
              {dic.ok}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectedPlaylist;
