import Skeleton from "@mui/material/Skeleton";

const ListLoading = () => {
  return (
    <>
      <Skeleton />
      <Skeleton variant="circular" width={20} height={20} />
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
      <Skeleton variant="rectangular" height={118} />
      <Skeleton animation="wave" />
    </>
  );
};

export default ListLoading;
