import { Box, Typography } from "@mui/material";
import { useDate } from "~/core/helper";
import MonibVerticalDivider from "./vertical.divider";
import persianJs from "persianjs";

const Date = () => {
  const date = useDate();

  return (
    <Box
      sx={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        height: 38,
      }}
    >
      <MonibVerticalDivider sx={{ height: 24 }} />
      <Box sx={{ flex: 1,textAlign:"center" }}>
        <Typography sx={{fontWeight:"bold"}} variant="subtitle1">
          {persianJs(date.dateFormat({ format: "jYYYY/jM/jD", onlyDate: true }))
            .englishNumber()
            .toString()}
        </Typography>
      </Box>
      <MonibVerticalDivider sx={{ height: 24 }} />
    </Box>
  );
};

export default Date;
