import * as React from "react";
import { findDOMNode } from "react-dom";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import dic from "~/data/dic";
import {
  Box,
  Typography,
  styled,
  IconButton,
  Collapse,
  CircularProgress,
  Fab,
  Badge,
} from "@mui/material";
import { CustomToggleButton } from "~/theme/components/custom.toggle";
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactPlayer from "react-player";
import { Link } from "@reach/router";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import screenfull from "screenfull";
import SelectedPlaylist from "~/Pages/Playlist/selected.list";
import PlayerSetting from "./player.setting";
import VolumeSetting from "./volume.setting";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import DownloadFile from "./download.file";
//import { FullScreen, useFullScreenHandle } from "react-full-screen";

const Sound = require("react-sound").default;

export const IconControl = styled(IconButton, {
  shouldForwardProp: (props) => props !== "size",
})(({ theme, size = "normal" }) => ({
  ...(size === "normal" && {
    width: 45,
    height: 45,
    img: {
      width: 30,
      height: 30,
    },
  }),
  ...(size === "large" && {
    width: 65,
    height: 65,
    img: {
      width: 55,
      height: 55,
    },
  }),
  margin: theme.spacing(0, 1),
}));

const theme = createTheme({
  direction: "lrt",
  palette: {
    mode: "light",
    primary: { light: "#86e0e6", main: "#40ba8d", dark: "#40ba8d" },
  },
});

const PlayerControl = ({
  lecture,
  defaultPosition = 0,
  onChangePosition,
  isMobile = false,
  type,
  verse,
  id,
}) => {
  const [state, setState] = React.useState("sound");
  const [volume, setVolume] = React.useState(100);
  const [value, setPosition] = React.useState(0);
  const [speedPlayer, setSpeedPlayer] = React.useState(1);
  const [videoValue, setVideoPosition] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [showDownload, setShowDownload] = React.useState(false);
  const [duration, setDuration] = React.useState(null);
  const [player, setPlayer] = React.useState(null);
  const [, setBufferWaiting] = React.useState(false);
  const [settingShow, setSettingShow] = React.useState(false);
  const [volumeSettingShow, setVolumeSettingShow] = React.useState(false);
  //const [share, setShare] = React.useState(false);
  const [playerRef, setPlayerRef] = React.useState(null);
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [show, setShowPlaylist] = React.useState(false);

  //const [newPosition, setNewPosition] = React.useState(defaultPosition);

  const ref = (p) => {
    setPlayerRef(p);
  };

  React.useEffect(() => setPosition(0), [setPosition, id]);

  const fullScreen = React.useCallback(() => {
    if (isFullscreen) {
      screenfull.exit();
    } else {
      screenfull.request(findDOMNode(playerRef));
    }
    setIsFullscreen((prv) => !prv);
  }, [playerRef, setIsFullscreen, isFullscreen]);

  const handleShare = React.useCallback(async () => {
    await navigator.share({
      // title: dic.title,
      text: `${lecture?.title}\n`,
      url: window.location.href,
    });
  }, [lecture]);

  React.useEffect(() => setPosition(defaultPosition), [defaultPosition]);

  React.useEffect(() => {
    player && player.seekTo(value / 1000);
    if (onChangePosition) {
      onChangePosition(value);
    }
  }, [player, value, onChangePosition]);

  const onReady = React.useCallback(
    (p) => {
      setPlayer(p);
    },
    [setPlayer]
  );

  setInterval(() => {
    if (player) setVideoPosition(player.getCurrentTime() * 1000);
  }, 100);

  const handleState = (_event, newState) => {
    if (newState === "sound") {
      setPosition(videoValue);
    } else {
      setIsPlaying(true);
    }

    setState(newState);
  };

  const handlePlaying = React.useCallback(
    (type) => (e) => {
      if (type === "sound") {
        setPosition(e.position);
      } else {
        setPosition(e.playedSeconds * 1000);
      }
    },
    [setPosition]
  );

  const onPlay = React.useCallback(
    () => setIsPlaying(!isPlaying),
    [setIsPlaying, isPlaying]
  );

  const onBuffer = React.useCallback(
    () => setBufferWaiting(true),
    [setBufferWaiting]
  );

  const onBufferEnd = React.useCallback(
    () => setBufferWaiting(false),
    [setBufferWaiting]
  );

  const changeValue = React.useCallback(
    (changeValue) => () => {
      let nValue = value + changeValue;
      if (nValue > duration) {
        nValue = duration;
      }
      if (nValue < 0) {
        nValue = 0;
      }
      setPosition(nValue);
    },
    [setPosition, value, duration]
  );

  const sliderChange = React.useCallback(
    (_e, value) => setPosition(value),
    [setPosition]
  );

  const handleLoading = React.useCallback(
    (e) => {
      setDuration(e.duration);
    },
    [setDuration]
  );

  const onBufferChange = React.useCallback(() => {}, []);

  const handlePlaylist = React.useCallback(
    () => setShowPlaylist((prv) => !prv),
    [setShowPlaylist]
  );

  const handleSettingShow = React.useCallback(
    (show) => () => setSettingShow(show),
    [setSettingShow]
  );

  const handleVolumeSettingShow = React.useCallback(
    (show) => () => setVolumeSettingShow(show),
    [setVolumeSettingShow]
  );

  const handleDownloadShow = React.useCallback(
    (show) => () => setShowDownload(show),
    [setShowDownload]
  );

  return (
    <>
      <DownloadFile
        {...lecture}
        show={showDownload}
        onClose={handleDownloadShow(false)}
      />
      <VolumeSetting
        show={volumeSettingShow}
        onClose={handleVolumeSettingShow(false)}
        soundPlayer={playerRef}
        volume={volume}
        setVolume={setVolume}
      />
      <PlayerSetting
        show={settingShow}
        onClose={handleSettingShow(false)}
        currentSpeed={speedPlayer}
        onChangeSpeed={(value) => setSpeedPlayer(value)}
      />
      <SelectedPlaylist
        lectureId={lecture?.lectureId}
        show={show}
        onClose={handlePlaylist}
      />
      <Sound
        url={lecture?.sound?.url}
        autoLoad={true}
        playStatus={
          isPlaying === true && state === "sound"
            ? Sound.status.PLAYING
            : Sound.status.PAUSED
        }
        position={value || 0}
        volume={volume}
        onLoading={handleLoading}
        onPlaying={handlePlaying("sound")}
        onBufferChange={onBufferChange}
        playbackRate={speedPlayer}
      />

     {lecture?.video && <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ToggleButtonGroup
          value={state}
          exclusive
          disabled={!lecture}
          onChange={handleState}
          aria-label="player state"
        >
          <CustomToggleButton value={"sound"}>
            <Typography component={"div"} sx={{ fontWeight: "bold", mr: 1 }}>
              {dic.sound}
            </Typography>
            <Box
              src={`/icons/${state === "sound" ? "sound-down" : "sound"}.svg`}
              component={"img"}
            />
          </CustomToggleButton>
          <CustomToggleButton value={"video"}>
            <Typography component={"div"} sx={{ fontWeight: "bold", mr: 1 }}>
              {dic.video}
            </Typography>
            <Box
              src={`/icons/${state === "video" ? "video-down" : "video"}.svg`}
              component={"img"}
            />
          </CustomToggleButton>
        </ToggleButtonGroup>
      </Box>}
      <Box sx={{ mt: 6 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row-reverse",
          }}
        >
          {/* <a
            rel="noreferrer"
            target={"_blank"}
            download
            disabled={!lecture}
            href={state === "sound" ? lecture?.sound.url : lecture?.video.url}
          > */}
          <IconControl onClick={handleDownloadShow(true)} disabled={!lecture}>
            <Box src={`/icons/download.svg`} component={"img"} />
          </IconControl>
          {/* </a> */}
          <IconControl disabled={!lecture} onClick={handlePlaylist}>
            <Box src={`/icons/myPlayList.svg`} component={"img"} />
          </IconControl>
          <IconControl disabled={!lecture} onClick={handleShare}>
            <Box src={`/icons/share.svg`} component={"img"} />
          </IconControl>
        </Box>

        <Box sx={{ borderRadius: 4, borderColor: "text.primary", border: 2 }}>
          <Collapse in={state === "video"}>
            <Box
              sx={{
                position: "relative",
                mt: 2,
                p: 1,
                // border: 2,
                // borderRadius: 4,
                // borderColor: "var(--main-color)",
                // bgcolor: "var(--main-color3)",
                "& >div": {
                  width: "100% !important",
                },
              }}
            >
              {(lecture || id) && (
                <>
                  {!player && (
                    <CircularProgress
                      sx={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        marginLeft: "-20px",
                        marginTop: "-20px",
                      }}
                    />
                  )}

                  {!isPlaying && (
                    <Fab
                      onClick={onPlay}
                      sx={{
                        position: "absolute",
                        zIndex: 99999,
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {isPlaying && (
                        <Box
                          component={"img"}
                          src="/icons/pause.svg"
                          alt="pause button"
                          sx={{
                            width: 55,
                            height: 55,
                          }}
                        />
                      )}
                      {!isPlaying && (
                        <Box
                          component={"img"}
                          src="/icons/play.svg"
                          alt="play button"
                          sx={{
                            width: 55,
                            height: 55,
                          }}
                        />
                      )}
                    </Fab>
                  )}

                  <Box
                    ref={ref}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minHeight: 100,
                      "& >div": {
                        width: "100% !important",
                      },
                    }}
                  >
                    <IconButton
                      onClick={fullScreen}
                      sx={{
                        bgcolor: "grey.900",
                        bottom: 25,
                        position: "absolute",
                        zIndex: 99999,
                        left: 20,
                        color: "white",
                        "&:hover": {
                          bgcolor: "grey.100",
                          color: "black",
                        },
                      }}
                    >
                      {isFullscreen ? (
                        <FullscreenExitIcon />
                      ) : (
                        <FullscreenIcon />
                      )}
                    </IconButton>

                    <ReactPlayer
                      playing={isPlaying && state === "video"}
                      onReady={onReady}
                      onBufferEnd={onBufferEnd}
                      onBuffer={onBuffer}
                      height="auto"
                      // playsinline
                      controls={isFullscreen}
                      url={
                        type === "quran"
                          ? `https://stream.monibapp.com/verse/${id}/${verse}.mp4`
                          : lecture?.video?.url
                      }
                    />
                  </Box>
                </>
              )}
            </Box>
          </Collapse>

          <Box
            sx={{
              mt: 1,
              alignItems: "center",
              // width: 1 / 1,
              minHeight: 60,
              display: "flex",
              px: 2,
              mx: {
                xs: 1,
                sm: 1,
                md: 0,
                lg: 0,
              },
            }}
          >
            <Typography sx={{ px: 2 }}>
              {duration ? duration.toHHMMSS() : "00:00:00"}
            </Typography>

            <ThemeProvider theme={theme}>
              <Slider
                disabled={!lecture}
                size="small"
                value={state === "sound" ? value : videoValue}
                max={duration || 1}
                onChange={sliderChange}
                sx={{
                  flex: 1,
                  "& .MuiSlider-thumb": {
                    background: "var(--main-color)",
                    transform: "translate(50%, -50%)",
                  },
                  "& .MuiSlider-track": {
                    background: "var(--main-color)",
                  },
                }}
              />
            </ThemeProvider>

            {state === "video" && (
              <Typography component={"div"} sx={{ px: 2 }}>
                {videoValue ? videoValue.toHHMMSS() : "00:00:00"}
              </Typography>
            )}
            {state === "sound" && (
              <Typography component={"div"} sx={{ px: 2 }}>
                {value ? value.toHHMMSS() : "00:00:00"}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row-reverse",
          }}
        >
          <IconControl
            onClick={handleVolumeSettingShow(true)}
            disabled={!duration || !lecture}
          >
            {volume === 0 ? (
              <VolumeOffIcon color="secondary" />
            ) : (
              <Box src={`/icons/volume.svg`} component={"img"} />
            )}
          </IconControl>
          <Box sx={{ flex: 1 }} />
          {type !== "quran" && duration && lecture?.previousId ? (
            <Link disabled to={`/player/${lecture?.previousId}`}>
              <IconControl
                title={dic.previousLecture}
                disabled={lecture?.previousId === null || !duration}
              >
                <ArrowBackIosIcon
                  sx={{
                    color: "text.primary",
                    ...((lecture?.previousId === null || !duration) && {
                      opacity: 0.5,
                    }),
                  }}
                />
              </IconControl>
            </Link>
          ) : (
            <IconControl disabled>
              <ArrowBackIosIcon
                sx={{
                  color: "text.primary",
                  opacity: 0.5,
                }}
              />
            </IconControl>
          )}
          {!isMobile && (
            <IconControl
              disabled={!duration || !lecture}
              onClick={changeValue(30 * -1000)}
            >
              <Box
                src={`/icons/30back.svg`}
                component={"img"}
                sx={{
                  ...((!lecture || !duration) && {
                    opacity: 0.5,
                  }),
                }}
              />
            </IconControl>
          )}
          <IconControl
            disabled={!duration || !lecture}
            onClick={onPlay}
            size="large"
            sx={{
              ...((!lecture || !duration) && {
                opacity: 0.5,
              }),
            }}
          >
            {isPlaying && (
              <Box
                component={"img"}
                src="/icons/pause.svg"
                alt="pause button"
                sx={{
                  width: 55,
                  height: 55,
                }}
              />
            )}
            {!isPlaying && (
              <Box
                component={"img"}
                src="/icons/play.svg"
                alt="play button"
                sx={{
                  width: 55,
                  height: 55,
                }}
              />
            )}
          </IconControl>
          {!isMobile && (
            <IconControl
              disabled={!lecture || !duration}
              sx={{
                ...((!lecture || !duration) && {
                  opacity: 0.5,
                }),
              }}
              onClick={changeValue(30 * 1000)}
            >
              <Box src={`/icons/30forward.svg`} component={"img"} />
            </IconControl>
          )}
          {type !== "quran" && duration && lecture ? (
            <Link to={`/player/${lecture?.nextId}`}>
              <IconControl
                title={dic.nextLecture}
                disabled={lecture?.nextId === null}
              >
                <ArrowForwardIosIcon
                  sx={{
                    color: "text.primary",
                    ...(lecture?.nextId === null && { opacity: 0.5 }),
                  }}
                />
              </IconControl>
            </Link>
          ) : (
            <IconControl disabled>
              <ArrowForwardIosIcon
                sx={{
                  color: "text.primary",
                  opacity: 0.5,
                }}
              />
            </IconControl>
          )}
          <Box sx={{ flex: 1 }} />
          <Badge
            color="primary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            badgeContent={speedPlayer !== 1 ? speedPlayer : null}
          >
            <IconControl
              sx={{
                ...((!lecture || !duration) && {
                  opacity: 0.5,
                }),
              }}
              disabled={!duration || !lecture}
              onClick={handleSettingShow(true)}
            >
              <Box src={`/icons/setting.svg`} component={"img"} />
            </IconControl>
          </Badge>
        </Box>
      </Box>
    </>
  );
};

export default PlayerControl;
