import {
  Dialog,
  DialogTitle,
  IconButton,
  Box,
  DialogContent,
  ButtonBase,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dic from "~/data/dic";

const Key = ({ title, icon, displaySize, disabled, link }) => {
  return (
    <Box
      component={"a"}
      sx={{
        flex: 1,
        border: 3,
        borderColor: "grey.500",
        mx: 0,
        p: 1,
        color: "text.primary",
        ...(disabled && { opacity: 0.5 }),
      }}
      rel="noreferrer"
      target={"_blank"}
      download
      disabled={!disabled}
      href={link}
    >
      <ButtonBase
        disabled={disabled}
        sx={{
          flex: 1,
          width: 1 / 1,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{ maxWidth: 30, maxHeight: 30, width: 1 / 1, height: 1 / 1 }}
          src={`/icons/${icon}.svg`}
          component={"img"}
        />
        <Typography>{title}</Typography>
        <Typography sx={{ direction: "rtl" }} variant="caption">
          {displaySize}
        </Typography>
      </ButtonBase>
    </Box>
  );
};

const DownloadFile = ({ show, sound, video, onClose }) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={show} onClose={onClose}>
      <DialogTitle>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flex: 1 }} />
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            width: 1 / 1,
            "& :first-of-type": {
              borderRight: "none",
              borderRadius: (theme) => theme.spacing(3, 0, 0, 3),
            },
            "& :last-of-type": {
              borderLeft: "none",
              borderRadius: (theme) => theme.spacing(0, 3, 3, 0),
            },
          }}
        >
          <Key
            icon="sound"
            link={sound?.url}
            title={dic.sound}
            displaySize={sound?.display}
          />
          <Key
            disabled={!video}
            icon="video"
            title={dic.video}
            link={video?.url}
            displaySize={video?.display}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadFile;
