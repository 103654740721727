import { Box } from "@mui/material";
import _ from "lodash";
import persianJs from "persianjs";

export const condition =
  (sore_id, is_list, currentPage = 1, mainId = 1) =>
  ({ verseId, indexPage, page }) => {
    if (mainId !== 1) {
      return true;
    }

    if (+sore_id === 1) {
      return is_list
        ? verseId !== 1 && verseId !== 0
        : verseId === 1 || verseId === 0;
    }

    return is_list
      ? page !== 0 && indexPage >= currentPage && indexPage <= currentPage
      : verseId === 0;
  };

export const verseList = ({ data, currentPage }) =>
  _.filter(
    data.list,
    (v) => v.page === currentPage && (data.surahsId !== 1 || v.verse !== 1)
  );

export const verseHeader = ({ data }) =>
  _.filter(
    data.list,
    (v) => v.verse === 0 || (data.surahsId === 1 && v.verse === 1)
  );


const Container = ({ isCenter, children }) =>
  isCenter ? (
    <Box
      sx={{
        width: 1 / 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  ) : (
    <>{children}</>
  );

const Text = ({ list, isCenter, onHandleVerse }) => {
  return _.map(list, (tx) => {
    return (
      <Container isCenter={isCenter}>
        {_.map(_.split(tx.arabicText, " "), (v, index) => {
          return (
            <Box
             // onClick={() => onHandleVerse({ verse: 0 })}
              sx={{
                mx: -0.4,
                px: 0.5,
                // color:
                //   +selectedVerse === v.verseId
                //     ? "primary.main"
                //     : "text.primary",
                zIndex: 0,
              }}
              key={`${tx?.verse}-${index}`}
            >
              {v}
            </Box>
          );
        })}
        {tx.verse !== 0 && (
          <Box
            sx={{
              position: "relative",
              whiteSpace: "nowrap",
              fontSize: {
                xs: "1.4rem",
                sm: "1.6rem",
                md: "1.6rem",
                lg: "1.6rem",
                xl: "1.6rem",
              },
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              mb: 0.5,
              mx: 0.5,
              px: {
                xs: 0.5,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
              },
              color: "primary.dark",
              minWidth: 40,
              width: "auto",
              justifyContent: "center",
              "&:after": {
                fontFamily: "TraditionalArabic",
                content: '"﴾"',
                position: "absolute",
                right: -3,
              },
              "&:before": {
                fontFamily: "TraditionalArabic",
                content: '"﴿"',
                position: "absolute",
                left: -3,
              },
            }}
          >
            {persianJs(tx.verse || "0")
              .englishNumber()
              .toString()}
          </Box>
        )}
      </Container>
    );
  });
};

const QuranText = ({
  data,
  currentPage = 0,
  min,
  onHandleVerse,
  selectedVerse,
}) => {
  return (
    <Box
      sx={{
        py: 1,
        px: 0,
        display: "flex",
        flex: 1,
        flexWrap: "wrap",
        fontFamily: "TraditionalArabic",
        fontWeight: "normal",
        fontStyle: "normal",
        justifyContent: "space-around",
        fontSize: {
          xs: "1.8rem",
          sm: "2.2rem",
          md: "2.2rem",
          lg: "2.2rem",
          xl: "2.2rem",
        },
        alignItems: "center",
       // cursor: "pointer",
      }}
    >
      {currentPage === min && (
        <Text
          list={verseHeader({ data })}
          onHandleVerse={onHandleVerse}
          selectedVerse={selectedVerse}
          isCenter={true}
        />
      )}
      <Text
        list={verseList({ data, currentPage })}
        onHandleVerse={onHandleVerse}
        selectedVerse={selectedVerse}
      />
      <Box sx={{ flex: 1 }} />
    </Box>
  );
};

export default QuranText;
