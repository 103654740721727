import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Config } from "~/core/config";
import logoSrc from "~/assets/icons/monibLogo.svg";
import { Box, Divider } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getAuthToken, fetchInfo } from "~/modules/Auth/authAction";
import { setupAxios } from "~/redux";
import axios from "axios";

const Progress = styled(CircularProgress)({
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -57,
  marginLeft: -57,
  zIndex: 2,
});

const ProgressLine = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -62,
  marginLeft: -62,
  zIndex: 1,
  "&.MuiCircularProgress-colorSecondary": {
    color: theme.palette.primary.main,
    animationDuration: "6s",
  },
}));

const Splash = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        bgcolor: "#fafafa",
        color: "text.primary",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          bgcolor: "grey.200",
          borderRadius: "50%",
          width: 100,
          height: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9,
          "&>img": {
            maxWidth: "80%",
            maxHeight: "80%",
          },
        }}
      >
        <img alt="logo" src={logoSrc} />
      </Box>
      <Progress size={114} thickness={2} color="secondary"></Progress>
      <ProgressLine size={124} thickness={1} color="secondary"></ProgressLine>

      <Box
        sx={{
          position: "absolute",
          bottom: 20,
          textAlign: "center",
          display: "flex",
          direction: "rtl",
          alignItems: "center",
        }}
      >
        <Typography>{Config.name}</Typography>
        <Divider orientation="vertical" sx={{ mx: 2, minHeight: 30 }} />
        <Typography variant="caption">ver: {Config.version}</Typography>
      </Box>
    </Box>
  );
};

const SplashForm = ({ children }) => {
  const dispatch = useDispatch();
  const { userData } = useSelector(({ auth }) => auth);

  useEffect(() => {
    setupAxios(axios);
    dispatch(fetchInfo);
  }, [dispatch]);

  const token = getAuthToken();

  return (
    <>
      {userData?.userId ||
      token === null ||
      token === undefined ||
      token === "undefined" ||
      userData?.error ? (
        children
      ) : (
        <Splash />
      )}
    </>
  );
  //return <>{children}</>;
};


export default SplashForm;