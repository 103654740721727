import * as React from "react";
import dic from "~/data/dic";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { navigate } from "@reach/router";

const PlayListItem = ({
  item,
  matches,
  isLecture,
  isTopic,
  isWisdom,
  onDelete,
  onFast,
}) => {
  const getLink = React.useCallback(() => {
    if (isWisdom) {
      return `/wisdom/${item.id}`;
    } else {
      return isTopic
        ? `/player/${item.lectureId}?goto=${item.startTime}`
        : `/player/${item.lectureId}`;
    }
  }, [isTopic, item, isWisdom]);

  return (
    <>
      <Box
        onClick={() => navigate(getLink())}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: 1 / 1,
          borderRadius: 4,
          flex: 1,
          height: 42,
          my: 1,
          justifyContent: "flex-start",
          px: { xs: 1, sm: 1, md: 2, lg: 2, xl: 2 },
          "&:hover": {
            boxShadow: (theme) =>
              `${alpha(theme.palette.primary.main, 0.3)} 0 0 0 0.2rem`,
          },
        }}
      >
        {isLecture && (
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="subtitle1"
          >
            {item.seriesName}
          </Typography>
        )}
        <Typography
          sx={{
            mx: 1,
            ...(matches && {
              fontSize: 12,
              wordBreak: "normal",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }),
          }}
          variant="subtitle1"
        >
          {isTopic ? item.text : item?.title}
        </Typography>
        <Box sx={{ flex: 1 }} />
        <IconButton disabled sx={{ opacity: 0.5 }}>
          <Box
            component={"img"}
            sx={{ width: matches ? 18 : 22 }}
            src="/icons/share.svg"
          />
        </IconButton>
        <IconButton
          onClick={onDelete({ show: true, item, isTopic, isLecture, isWisdom })}
        >
          <Box
            component={"img"}
            sx={{ width: matches ? 18 : 22 }}
            src="/icons/delete.svg"
          />
        </IconButton>

        <Button
          variant="contained"
          onClick={onFast(item)}
          startIcon={
            !matches && (
              <Box
                component={"img"}
                sx={{ width: matches ? 10 : 12 }}
                src="/icons/quickPlay.svg"
              />
            )
          }
          sx={{
            borderRadius: 5,

            minWidth: { xs: 30, sm: 30, md: 90, lg: 90, xl: 90 },
            color: "white",
            ...(matches && { fontSize: 10 }),
            bgcolor: "var(--main-color)",
          }}
        >
          {!matches && dic.quickPlay}
          {matches && (
            <Box
              component={"img"}
              sx={{ width: matches ? 10 : 12 }}
              src="/icons/quickPlay.svg"
            />
          )}
        </Button>
      </Box>
      <Divider />
    </>
  );
};

export default PlayListItem;
