import { Box, IconButton, styled, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import { getContextBySeriesIdAsync } from "~/modules/context/contextCrud";
import QuranText from "./text";
import dic from "~/data/dic";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import ForwardIcon from "@mui/icons-material/ArrowForwardIosOutlined";

const Loading = () => (
  <>
    <Skeleton />
    <Skeleton variant="circular" width={20} height={20} />
    <Skeleton />
    <Skeleton animation={false} />
    <Skeleton animation="wave" />
    <Skeleton variant="rectangular" height={118} />
    <Skeleton animation="wave" />
  </>
);

const Frame = styled(Box)(() => ({
  border: "2px solid var(--border-color)",
  minWidth: 130,
  borderRadius: 6 * 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const GoTo = ({ value, onNext, onBack, backDisabled, nextDisabled }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <IconButton disabled={backDisabled} onClick={onBack} size="small">
      <ForwardIcon color={backDisabled ? "text.secondary" : "primary"} />
    </IconButton>
    <Typography sx={{ mx: 0 }} component={"div"}>
      {value.toPersianNumber()}
    </Typography>
    <IconButton disabled={nextDisabled} onClick={onNext} size="small">
      <BackIcon color={nextDisabled ? "text.secondary" : "primary"} />
    </IconButton>
  </Box>
);

const Header = ({ page, onNext, onBack, currentPage, maxPage, minPage }) => {
  return (
    <Box
      component={"header"}
      sx={{
        minHeight: 36,
        display: "flex",
        alignItems: "stretch",
        justifyContent: {
          xs: "center",
          sm: "center",
          md: "center",
          lg: "center",
          xl: "center",
        },
        mt: 2,
        mb: 5,
      }}
    >
      <Frame>
        <Typography component={"div"}>{dic.page}</Typography>
        <GoTo
          onNext={onNext}
          onBack={onBack}
          value={page}
          backDisabled={currentPage === minPage}
          nextDisabled={currentPage === maxPage}
        />
      </Frame>
      {/* <Frame sx={{ ml: 1 }}>
        <Typography component={"div"}>{dic.verse}</Typography>
        <Box
          sx={{
            width: 20,
            mx: 0.5,
            height: 22,
            borderRadius: 1,
            bgcolor: "grey.200",
          }}
        />
        <IconButton size="small">
          <Box component={"img"} src={GoIcon} sx={{ width: 14, height: 14 }} />
        </IconButton>
       
      </Frame> */}
    </Box>
  );
};

const Quran = ({ seriesId }) => {
  const [data, setText] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [min, setMin] = React.useState(1);
  const [max, setMax] = React.useState(10);

  // const onHandleVerse = ({ verse }) => {
  //   navigate(`/quran-player/${seriesId}/${verse}`);
  // };

  React.useEffect(() => {
    if (data) {
      const { min, max } = data;
      setMin(min);
      setMax(max);
      setCurrentPage(min);
    }
  }, [setMax, setMin, setCurrentPage, data]);

  React.useEffect(() => {
    const fetch = async () => {
      const list = await getContextBySeriesIdAsync({ seriesId });
      setText(list.data);
    };
    if (seriesId) {
      fetch();
    }
  }, [setText, seriesId]);

  const nextPage = React.useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [setCurrentPage, currentPage]);

  const backPage = React.useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [setCurrentPage, currentPage]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column-reverse",
          sm: "column",
          md: "column",
          lg: "column",
          xl: "column",
        },
      }}
    >
      {!data && <Loading />}
      {data && (
        <Header
          onNext={nextPage}
          onBack={backPage}
          currentPage={currentPage}
          page={currentPage}
          maxPage={max}
          minPage={min}
          verseCount={data.list.length}
        />
      )}
      {data && (
        <QuranText
          currentPage={currentPage}
          data={data}
          min={min}
          // selectedVerse={selectedVerse}
          // onHandleVerse={onHandleVerse}
        />
      )}
    </Box>
  );
};

export default Quran;
