import React, { useEffect, useState, useCallback } from "react";
import dic from "~/data/dic";
import {
  Box,
  Button,
  Collapse,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import MonibTextField from "~/theme/components/textField";
import { useSelector } from "react-redux";
import _ from "lodash";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";
import useMediaQuery from "@mui/material/useMediaQuery";

const MobilePart = ({
  mode,
  onChange,
  isSubmit,
  activeCode,
  errorMessage,
  onChangeMode,
  mobile,
  cca2,
  cioc,
  onActiveCode,
  onSubmit,
  isReadOnly = false,
  hasActive = true,
  onResend,
  showAction = true,
}) => {
  const { list } = useSelector((state) => state.callingCodes);
  const [time, setTimer] = useState(10); // 2 minute
  const [current, setCurrent] = React.useState("");

  useEffect(() => {
    setTimer(10);
  }, [mode, setTimer]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (time >= 0) {
        setTimer(time - 1);
        setCurrent(new Date(time * 1000).toISOString().substring(13, 19));
      }
    }, [1000]);

    return () => clearInterval(timer);
  }, [time, setCurrent, setTimer]);

  const handleResend = useCallback(() => {
    setTimer(120);
    onResend();
  }, [onResend, setTimer]);

  const matches = useMediaQuery("(max-width:480px)");
  return (
    <>
      <Collapse in={mode === "code"}>
        <Box
          sx={{
            mt: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              whiteSpace: {
                xs: "pre-wrap",
                sm: "pre-wrap",
                md: "nowrap",
                lg: "nowrap",
                xl: "nowrap",
              },
              lineHeight: 2,
            }}
          >
            {`لطفا کد ارسال شده به شماره \n ${mobile} \n را وارد نمایید`}
          </Typography>
          <MonibTextField
            inputProps={{
              style: { textAlign: "center" },
            }}
            ltr
            onChange={onChange}
            name="activeCode"
            error={isSubmit && !activeCode}
            required
            value={activeCode}
            fullWidth={false}
            sx={{ direction: "rtl", maxWidth: 160, margin: "auto" }}
          />
        </Box>

        <Box sx={{ mt: 3, textAlign: "center" }}>
          {isSubmit && (!activeCode || activeCode.length !== 4) && (
            <Typography sx={{ mb: 2, color: "secondary.main" }}>
              {dic.required_code}
            </Typography>
          )}
          {errorMessage && (
            <Typography sx={{ mb: 2, color: "secondary.main" }}>
              {errorMessage}
            </Typography>
          )}
          {hasActive && (
            <>
              <Button
                disableElevation
                onClick={onChangeMode("mobile")}
                sx={{
                  minWidth: 100,
                }}
              >
                {dic.changeMobile}
              </Button>

              <Button
                variant="contained"
                disableElevation
                onClick={onActiveCode}
                sx={{
                  borderRadius: 4,
                  mx: 2,
                  color: "white",
                  minWidth: 100,
                }}
              >
                {dic.login}
              </Button>
              <Box sx={{ mt: 2 }}>
                <Button
                  disabled={time > 0}
                  onClick={handleResend}
                  sx={{
                    minWidth: 100,
                  }}
                >
                  ارسال مجدد {current}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Collapse>
      <Collapse in={mode === "mobile"}>
        <Box sx={{ mt: 5, display: "flex", alignItems: "flex-end" }}>
          <MonibTextField
            inputProps={{
              readOnly: isReadOnly,
              style: { textAlign: "center" },
            }}
            ltr
            label={dic.mobile}
            onChange={onChange}
            name="mobile"
            error={isSubmit && (!mobile || !isValidPhoneNumber(mobile, cca2))}
            required={!isReadOnly}
            value={mobile}
            sx={{ direction: "rtl" }}
          />
          {list && cioc && !isReadOnly && (
            <Select
              value={cioc}
              sx={{
                ml: 1,
                minWidth: { sx: 40, sm: 40, md: 160, lg: 160, xl: 160 },
              }}
              name="cioc"
              onChange={onChange}
            >
              {_.map(list, ({ code, fa, cioc }, index) => (
                <MenuItem key={`${cioc}-${index}`} value={cioc}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: 1 / 1,
                    }}
                  >
                    {!matches && <div>{fa}</div>}
                    <Box component={"b"} sx={{ direction: "rtl" }}>
                      {code}
                    </Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
        {!isReadOnly && showAction && (
          <Box sx={{ mt: 3, textAlign: "center" }}>
            {isSubmit && (!mobile || !isValidPhoneNumber(mobile, cca2)) && (
              <Typography sx={{ mb: 2, color: "secondary.main" }}>
                {dic.required_mobile}
              </Typography>
            )}
            {hasActive && (
              <Button
                variant="contained"
                disableElevation
                onClick={onSubmit}
                sx={{
                  borderRadius: 4,
                  mt: 1,
                  color: "white",
                  margin: "auto",
                  minWidth: 100,
                }}
              >
                {"ورود"}
              </Button>
            )}
          </Box>
        )}
      </Collapse>
    </>
  );
};

export default MobilePart;
