import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Typography, Box, IconButton } from "@mui/material";
import _ from "lodash";
import PlayListItem from "./items";
import dic from "~/data/dic";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, expanded }) => ({
  background: "transparent",
  borderRadius: 8,
  margin: theme.spacing(1, 0),
  border: `2px solid  ${
    expanded ? theme.palette.primary.main : "var(--border-color)"
  }`,
  "&:not(:last-child)": {},
  "&:before": {
    display: "none",
  },
  "& .Mui-expanded": {
    // border: `2px solid ${theme.palette.primary.main}`,
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    alignItems: "center",
    height: 50,
    display: "flex",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(0),
      alignItems: "center",
      display: "flex",
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  //  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const MobileList = ({
  myList,
  onDelete,
  onFast,
  onAdd,
  onEdit,
  onWisdomPlayer,
}) => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = React.useCallback(
    (panel) => (_event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    },
    [setExpanded]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        py: 2,
      }}
    >
      <Button
        onClick={onAdd({ show: true })}
        variant="contained"
        endIcon={<AddIcon />}
      >
        {dic.addPlaylist}
      </Button>
      <Box
        sx={{
          py: 2,
          px: { xs: 1, sm: 1, md: 2, lg: 2, xl: 2 },
          pb: 6,
          width: 1 / 1,
        }}
      >
        {_.map(myList, (item) => (
          <Accordion
            expanded={expanded === `panel${item.id}`}
            onChange={handleChange(`panel${item.id}`, item.id)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>{item.title}</Typography>
              <Typography sx={{ mx: 1, fontSize: 12 }} variant="subtitle1">
                {item.date}
              </Typography>
              <Box sx={{ flex: 1 }} />
              <IconButton>
                <Box
                  disabled
                  component={"img"}
                  sx={{ width: 16, opacity: 0.5 }}
                  src="/icons/share.svg"
                />
              </IconButton>
              <IconButton onClick={onDelete}>
                <Box
                  component={"img"}
                  sx={{ width: 16 }}
                  src="/icons/delete.svg"
                />
              </IconButton>
              <IconButton onClick={onEdit({ show: true, playlist: item })}>
                <EditIcon />
              </IconButton>
            </AccordionSummary>
            <AccordionDetails>
              {_.map(item?.content?.lectures, (item, index) => (
                <PlayListItem
                  key={`${item.id}-${item.lectureId}-${index}`}
                  onDelete={onDelete}
                  onFast={onFast}
                  item={item}
                  isLecture
                  matches={true}
                />
              ))}
              {_.map(item?.content?.topics, (item, index) => (
                <PlayListItem
                  key={`${item.id}-${item.topicId}-${index}`}
                  onDelete={onDelete}
                  item={item}
                  onFast={onFast}
                  isTopic
                  matches={true}
                />
              ))}
              {_.map(item?.content?.wisdom, (item, index) => (
                <PlayListItem
                  key={`${item.id}-${item.id}-${index}`}
                  onDelete={onDelete}
                  item={item}
                  onFast={onWisdomPlayer}
                  isWisdom
                  matches={true}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default MobileList;
