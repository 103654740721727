import { createSlice } from "@reduxjs/toolkit";

const initialThemeState = {
  themeId: "m1",
  mode: "light",
  color: "blue",
  direction: "rtl",
  fontSize: "normal",
};

export const themeSlice = createSlice({
  name: "theme",
  initialState: initialThemeState,
  reducers: {
    changeThemeId: (state, action) => {
      state.error = null;
      state.themeId = action.payload;
    },
  },
});
