import { createSlice } from "@reduxjs/toolkit";

const initialLiveState = {
  device: null,
  sliders: null,
  isLive: false,
  count: {},
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const homeSlice = createSlice({
  name: "home",
  initialState: initialLiveState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    homeFetched: (state, action) => {
      const { sliders, device, count, isLive, liveCounter } = action.payload;

      state.error = null;
      state.sliders = sliders;
      state.device = device;
      state.count = count;
      state.isLive = isLive;
      state.liveCounter = liveCounter;
    },
  },
});
