import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import dic from "~/data/dic";
import MonibTextField from "~/theme/components/textField";
import LoadingButton from "@mui/lab/LoadingButton";
import { addPlaylist, editPlaylist } from "~/modules/PlayList/playListCrud";
import { fetchPlayList } from "~/modules/PlayList/playListAction";
import { useDispatch } from "react-redux";

const ManagePlaylist = ({ playlist, show, onClose }) => {
  const [{ title, id }, setValue] = React.useState({});
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (playlist) {
      setValue(playlist);
    } else {
      setValue({});
    }
  }, [playlist, setValue]);

  const handleChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setValue((prv) => ({
        ...prv,
        [name]: value,
      }));
    },
    [setValue]
  );

  const submit = React.useCallback(() => {
    setIsSubmit(true);
    if (title) {
      setIsLoading(true);
      const action = id ? editPlaylist : addPlaylist;
      action({ title, id }, () => {
        setIsLoading(false);
        setIsSubmit(false);
        dispatch(fetchPlayList);
        onClose();
      });
    }
  }, [setIsSubmit, title, id, onClose, setIsLoading, dispatch]);

  React.useEffect(() => {
    setIsLoading(false);
    setIsSubmit(false);
  }, [show, setIsLoading, setIsSubmit]);

  return (
    <Dialog maxWidth="sm" open={show} onClose={onClose}>
      <DialogContent>
        <MonibTextField
          onChange={handleChange}
          name="title"
          value={title}
          error={isSubmit && !title}
          label={dic.playlistTitle}
        />
        {isSubmit && !title && (
          <Typography sx={{ color: "secondary.main", mt: 1, px: 2 }}>
            {dic.enterTitle}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{dic.cancel}</Button>
        <LoadingButton loading={isLoading} onClick={submit} variant="contained">
          {dic.ok}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ManagePlaylist;
