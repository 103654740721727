import Api from "~/core/helper/apiHelper";
const { default: urlcat, join } = require("urlcat");

const PLAY_LIST = "user/playList/";

export function getList() {
  return Api.asyncGet(urlcat(PLAY_LIST));
}

export function addPlaylist(data, callback) {
  return Api.post(urlcat(PLAY_LIST), data, callback);
}

export function editPlaylist({ id, ...data }, callback) {
  return Api.put(urlcat(`${PLAY_LIST}:id`, { id }), data, callback);
}

export function lectureToPlaylist(data, callback) {
  return Api.put(join(PLAY_LIST, "/", "lecture"), data, callback);
}

export function topicToPlaylist(data, callback) {
  return Api.put(join(PLAY_LIST, "/", "topic"), data, callback);
}

export function removeLectureFromPlaylist(data, callback) {
  return Api.deleteWithBody(join(PLAY_LIST, "/", "lecture"), data, callback);
}

export function removeTopicFromPlaylist(data, callback) {
  return Api.deleteWithBody(join(PLAY_LIST, "/", "topic"), data, callback);
}

export function removeWisdomFromPlaylist(data, callback) {
  return Api.deleteWithBody(join(PLAY_LIST, "/", "wisdom"), data, callback);
}

export function deletePlaylist({ id }, callback) {
  return Api.delete(urlcat(`${PLAY_LIST}:id`, { id }), callback);
}

export function contentPlaylist({ id }, callback) {
  return Api.get(urlcat(`${PLAY_LIST}content/:id`, { id }), callback);
}


export function wisdomToPlaylist(data, callback) {
  return Api.put(join(PLAY_LIST, "/", "wisdom"), data, callback);
}