import {
  Box,
  ButtonBase,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import _ from "lodash";
//import ShareControl from "./share.control";
import dic from "~/data/dic";
import SelectedPlaylist from "~/Pages/Playlist/selected.list";
import ShareIcon from "~/assets/icons/share.svg";
import PlaylistIcon from "~/assets/icons/myPlayList.svg";
import { getCurrentDomain } from "~/core";

const SubjectList = ({ list, position, onClick, lectureId, title }) => {
  //const [share, setShare] = React.useState(false);
  //const [currentSubject, setCurrentSubject] = React.useState(null);
  const [{ show, topicId }, setShowPlaylist] = React.useState({ show: false });

  // const handleShare = (open, subject) => (e) => {
  //   setShare(open);
  //   setCurrentSubject(subject);
  //   e.stopPropagation();
  // };

  const handlePlaylist = React.useCallback(
    ({ show, topicId }) =>
      (event) => {
        event?.stopPropagation();
        setShowPlaylist({ show, topicId });
      },
    [setShowPlaylist]
  );

  const handleShare = React.useCallback(
    ({ startPosition, ...item }) =>
      async () => {
        
        await navigator.share({
          // title: dic.title,
          text: `${title} ${item.title}\n`,
          url: `https://${getCurrentDomain()}/player/${lectureId}/?goto=${startPosition}`,
        });
      },
    [title, lectureId]
  );

  return (
    <>
      <SelectedPlaylist
        topicId={topicId}
        show={show || false}
        onClose={handlePlaylist({ show: false })}
      />
      {/* <ShareControl
        link={`player/${lectureId}/?goto=${currentSubject?.startPosition}`}
        open={share}
        text={`${title} %0a ${currentSubject?.title} %0a%0a ${dic.title}`}
        toggleDialog={handleShare(false)}
      /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1 / 1,
          mt: 5,
          my: 6,
        }}
      >
        {_.map(list, (item, index) => (
          <React.Fragment key={index}>
            <ButtonBase
              component="div"
              onClick={() => onClick && onClick(item)}
              sx={{
                minHeight: 56,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                width: 1 / 1,
                px: 1,
              }}
            >
              <Typography
                sx={{
                  ...(position >= item.startPosition &&
                    position <= item.endPosition && {
                      color: "primary.main",
                      fontWeight: "bold",
                    }),
                }}
                variant="subtitle1"
                component={"div"}
              >
                {item.title}
              </Typography>
              {item.categoryId === 1 && (
                <Box
                  sx={{
                    mx: 1,
                    fontSize: 9,
                    px: 1,
                    py: 0.2,
                    color: "white",
                    bgcolor: "primary.main",
                    borderRadius: 3,
                  }}
                >
                  {dic.terminology}
                </Box>
              )}
              <Box sx={{ flex: 1 }} />
              <Typography
                variant="caption"
                sx={{ mr: 0.5, direction: "rtl" }}
                component={"div"}
              >
                {(item.endPosition - item.startPosition)
                  .toHHMMSS()
                  .substring(3, 9)}
                {"''"}
              </Typography>
              <IconButton
                onClick={handlePlaylist({ show: true, topicId: item.id })}
              >
                <Box sx={{ width: 18 }} src={PlaylistIcon} component={"img"} />
              </IconButton>
              <IconButton onClick={handleShare(item)}>
                <Box sx={{ width: 18 }} src={ShareIcon} component={"img"} />
              </IconButton>
            </ButtonBase>
            <Divider />
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

export default SubjectList;
