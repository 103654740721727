import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, Collapse } from "@mui/material";
import { getLectureByIdAsync } from "~/modules/lecture/lectureCrud";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import { IconControl } from "~/components/player.control";

const Sound = require("react-sound").default;

const theme = createTheme({
  direction: "lrt",
  palette: {
    mode: "light",
    primary: { light: "#86e0e6", main: "#40ba8d", dark: "#40ba8d" },
  },
});

const FastPlayer = ({ lectureId, defaultPosition, text,endTime }) => {  
  const [value, setPosition] = React.useState(0);
  const [data, setData] = useState(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [duration, setDuration] = React.useState(null);

  React.useEffect(() => setPosition(defaultPosition), [defaultPosition]);
  const matches = useMediaQuery("(max-width:480px)");

  const sliderChange = React.useCallback(
    (_e, value) => setPosition(value),
    [setPosition]
  );

  const handlePlaying = React.useCallback(
    (type) => (e) => {
      if (type === "sound") {
        setPosition(e.position);
      } else {
        setPosition(e.playedSeconds * 1000);
      }
    },
    [setPosition]
  );

  const handleLoading = React.useCallback(
    (e) => {
      setDuration(e.duration);
    },
    [setDuration]
  );

  const onPlay = React.useCallback(
    () => setIsPlaying(!isPlaying),
    [setIsPlaying, isPlaying]
  );

  const changeValue = React.useCallback(
    (changeValue) => () => {
      let nValue = value + changeValue;
      if (nValue > duration) {
        nValue = duration;
      }
      if (nValue < 0) {
        nValue = 0;
      }
      setPosition(nValue);
    },
    [setPosition, value, duration]
  );

  useEffect(() => {
    const fetch = async () => {
      const d = await getLectureByIdAsync({ lectureId });
      setData(d?.data);
    };
    if (lectureId) fetch();
  }, [setData, lectureId]);

  return (
    <Collapse in={data !== null}>
      <>
        <Sound
          url={data?.sound?.url}
          autoLoad={true}
          playStatus={
            isPlaying === true ? Sound.status.PLAYING : Sound.status.PAUSED
          }
          position={value || 0}
          onLoading={handleLoading}
          onPlaying={handlePlaying("sound")}
        />

        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ fontWeight: "bold", mx: 2 }}>{text}</Typography>
            <Box sx={{ flex: 1 }} />
            {data?.mainId === 1 && (
              <>
                <Typography
                  color="black"
                  sx={{ ...(matches && { fontSize: 14 }) }}
                  variant="h6"
                >
                  {data?.contextRow}
                </Typography>
                <Divider
                  orientation="vertical"
                  sx={{
                    minHeight: { xs: 13, md: 20, sm: 13, lg: 20, xl: 20 },
                    mx: 1,
                    border: 1,
                  }}
                />
              </>
            )}
            <Typography>{data?.title}</Typography>
          </Box>
          <Divider sx={{ mt: 1 }} />
          <Box
            sx={{
              mt: 1,
              alignItems: "center",
              // width: 1 / 1,
              minHeight: 60,
              display: "flex",
              px: {
                xs: 0,
                sm: 0,
                md: 2,
                lg: 2,
                xl: 2,
              },
              mx: {
                xs: 1,
                sm: 1,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
          >
            <Typography sx={{ px: 2 }}>
              {duration ? duration.toHHMMSS() : "00:00:00"}
            </Typography>

            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  width: 1 / 1,
                  bgcolor: "grey.100",
                  height: 2,
                  mt: 0,
                  position: "relative",
                }}
              >
                <Box
                  sx={{                    
                    height: 2,
                    bgcolor: "primary.main",
                    position: "absolute",
                    top: 0,
                    width:`${endTime*100/duration - defaultPosition*100/duration}%`,
                    right:`${defaultPosition*100/duration}%`
                  }}
                ></Box>
                <Box
                  sx={{
                    width: 2,
                    height: 10,
                    bgcolor: "primary.main",
                    position: "absolute",
                    top: -4,
                    right:`${defaultPosition*100/duration}%`
                  }}
                ></Box>
                <Box
                  sx={{
                    width: 2,
                    height: 10,
                    bgcolor: "primary.main",
                    position: "absolute",
                    top: -4,
                    right:`${endTime*100/duration}%`
                  }}
                ></Box>
              </Box>
              <ThemeProvider theme={theme}>
                <Slider
                  size="small"
                  value={value}
                  max={duration}
                  onChange={sliderChange}
                  sx={{
                    flex: 1,
                    "& .MuiSlider-thumb": {
                      background: "var(--main-color)",
                      transform: "translate(50%, -50%)",
                    },
                    "& .MuiSlider-track": {
                      background: "var(--main-color)",
                    },
                  }}
                />
              </ThemeProvider>
            </Box>

            <Typography component={"div"} sx={{ px: 2 }}>
              {value ? value.toHHMMSS() : "00:00:00"}
            </Typography>
          </Box>

          {duration && (
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row-reverse",
              }}
            >
              <IconControl disabled>
                <Box
                  src={`/icons/volume.svg`}
                  sx={{ opacity: 0.5 }}
                  component={"img"}
                />
              </IconControl>
              <Box sx={{ flex: 1 }} />
              {!matches && (
                <IconControl onClick={changeValue(30 * -1000)}>
                  <Box src={`/icons/30back.svg`} component={"img"} />
                </IconControl>
              )}
              <IconControl onClick={onPlay} size="large">
                {isPlaying && (
                  <Box
                    component={"img"}
                    src="/icons/pause.svg"
                    alt="pause button"
                    sx={{
                      width: 55,
                      height: 55,
                    }}
                  />
                )}
                {!isPlaying && (
                  <Box
                    component={"img"}
                    src="/icons/play.svg"
                    alt="play button"
                    sx={{
                      width: 55,
                      height: 55,
                    }}
                  />
                )}
              </IconControl>
              {!matches && (
                <IconControl onClick={changeValue(30 * 1000)}>
                  <Box src={`/icons/30forward.svg`} component={"img"} />
                </IconControl>
              )}
              <Box sx={{ flex: 1 }} />
              <IconControl disabled>
                <Box
                  src={`/icons/setting.svg`}
                  sx={{ opacity: 0.5 }}
                  component={"img"}
                />
              </IconControl>
            </Box>
          )}
        </Box>
      </>
    </Collapse>
  );
};

export default FastPlayer;
