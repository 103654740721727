import React from "react";
import { Box, ButtonBase, Typography, Grid } from "@mui/material";
import dic from "~/data/dic";
import { Link } from "@reach/router";
import AdvancedSearch from "~/theme/components/advanced.search";
import Clock from "~/theme/components/date";
import { useEffect } from "react";
import moment from "moment";

export const Item = ({ title, icon, link, isTop = false }) => {
  return (
    <Link to={link || "/"}>
      <ButtonBase
        sx={[
          {
            height: {
              md: 106,
              sm: 70,
              xs: 70,
            },
            ...(isTop && { mt: 0.5 }),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            border: 3,
            color: "text.primary",
            borderColor: "var(--border-color)",
            width: 1 / 1,
            borderRadius: 4,
            flex: 1,
          },
          (theme) => ({
            "& img": {
              height: 34,
              width: 34,
            },
            [theme.breakpoints.down("md")]: {
              height: 84,
              border: 2,
              "& img": {
                height: 38,
                width: 38,
              },
            },
          }),
        ]}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 1 / 1,
          }}
        >
          <Box component={"img"} sx={{ flex: 1 }} alt="icon" src={icon} />
        </Box>
        <Typography
          sx={{
            fontWeight: "bold",
            // whiteSpace: "pre-wrap",
            // inlineSize: "min-content",
            // overflowWrap: "break-word",
            flexBasis: { xs: 30, sm: 30, md: 40 },
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-vox",
            px: 1,
            fontSize: 12,
          }}
          variant="subtitle2"
        >
          {title}
        </Typography>
      </ButtonBase>
    </Link>
  );
};

const Timer = React.memo(({ time }) => {
  const [current, setCurrent] = React.useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      let [H, M, S] = time.split(/\W/);
      const base = +H * 60 * 60 + +M * 60 + +S;
      if (base > 0) {
        const d = moment(new Date()).format("HH:mm:ss");
        let [H, M, S] = d.split(/\W/);
        let ss = base - (+H * 60 * 60 + +M * 60 + +S);
        setCurrent(new Date(ss * 1000).toISOString().substring(11, 19));
      }
    }, [1000]);

    return () => clearInterval(timer);
  }, [time, setCurrent]);

  return <Box>{current}</Box>;
});

export const Live = ({ isLive = false, liveCounter }) => (
  <Link to={"/live"}>
    <ButtonBase
      sx={[
        {
          height: {
            md: 106,
            lg: 106,
            xl: 106,
            sm: 106,
          },
          // maxWidth: { xs: 300, sm: 300, md: 1/1},
          margin: "auto",
          display: "flex",
          ...((isLive || liveCounter?.time) && { bgcolor: "secondary.dark" }),
          ...(!isLive && !liveCounter?.time && { bgcolor: "grey.600" }),
          borderRadius: 4,
          flex: 1,
          width: 1 / 1,
        },
        (theme) => ({
          [theme.breakpoints.down("md")]: {
            height: 84,
          },
        }),
      ]}
    >
      <Box
        sx={[
          {
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            "& >img": {
              height: 50,
              width: 50,
            },
          },
          (theme) => ({
            [theme.breakpoints.down("md")]: {
              "& >img": {
                height: 38,
                width: 38,
              },
            },
          }),
        ]}
      >
        <img alt="live" src="/icons/live.svg" />
        <Typography
          component={"div"}
          sx={{ mt: 0.5, color: "white" }}
          variant="subtitle1"
        >
          {dic.live}
        </Typography>
      </Box>
      {liveCounter?.time && !isLive && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "white" }}>{dic.toLive}</Typography>
          <Typography sx={{ color: "white" }} variant="h6">
            <Timer time={liveCounter?.time} />
          </Typography>
        </Box>
      )}
    </ButtonBase>
  </Link>
);

export const QuranMonib = () => {
  return (
    <Box href="https://quran.monibapp.ir/" component={"a"}>
      <Box
        sx={{
          height: 70,
          bgcolor: "#000067",
          borderRadius: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: "url(/images/quran-monib-p.jpg)",
        }}
      >
        <Box
          component={"img"}
          src="/icons/quran-monib.svg"
          sx={{ objectFit: "cover", width: "80%" }}
        />
      </Box>
    </Box>
  );
};

const Items = ({ isLive = false, liveCounter, uk }) => {
  return (
    <Grid container spacing={0.5}>
      <Grid item xs={uk ? 12 : 8}>
        <AdvancedSearch />
      </Grid>
      {!uk && (
        <Grid item xs={4}>
          <Clock />
        </Grid>
      )}
      <Grid sx={{ mt: 1 }} spacing={0.5} container>
        <Grid item xs={12}>
          <QuranMonib />
        </Grid>
        <Grid item xs={12}>
          <Live isLive={isLive} liveCounter={liveCounter} />
        </Grid>
        {/* <Grid item xs={4}>
          <Item icon="/icons/podcast.svg" link="/podcast" title={dic.podcast} />
        </Grid> */}
        {!uk && (
          <Grid item xs={4}>
            <Item
              icon="/icons/schedule.svg"
              link="/schedule"
              title={dic.schedule}
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <Item icon="/icons/last.svg" link="/last" title={dic.last} />
        </Grid>
        <Grid item xs={4}>
          <Item
            icon="/icons/wisdom.svg"
            link="/wisdom"
            //link="/underConstruction"
            title={dic.wisdom}
          />
        </Grid>
        <Grid item xs={4}>
          <Item
            icon="/icons/myPlayList.svg"
            link="/user/playlist"
            title={dic.myPlayList}
          />
        </Grid>
        <Grid item xs={4}>
          <Item
            icon="/icons/view.svg"
            link="/user/last-view"
            title={dic.lastView}
          />
        </Grid>
        <Grid item xs={4}>
          <Item icon="/icons/support.svg" link="/support" title={dic.support} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Items;
