import * as React from "react";
import dic from "~/data/dic";
import { Box, Divider, Typography } from "@mui/material";
import PageDivider from "~/theme/components/page.divider";
import { getLectureByIdAsync } from "~/modules/lecture/lectureCrud";
import PlayerControl from "~/components/player.control";
import SubjectText from "~/components/subject.text";
import useMediaQuery from "@mui/material/useMediaQuery";
import persianJs from "persianjs";
import BaseLayout from "~/Layout/Main.Layout";
import { title, link } from "~/data/public.data";
import queryString from "query-string";
import { useLocation } from "@reach/router";

const Header = React.memo(({ data, matches }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: 80,
          borderRadius: 4,
          //bgcolor: "var(--main-color4)",
          bgcolor: "primary.main",
          borderColor: "text.primary",
          border: 2,
          mb: 1,
          px: 3,
        }}
      >
        {data?.mainId === 1 && (
          <>
            <Typography
              color="black"
              sx={{ fontWeight: "bold", ...(matches && { fontSize: 14 }) }}
              variant="h5"
            >
              {data?.contextRow}
            </Typography>
            <Divider
              orientation="vertical"
              sx={{
                minHeight: { xs: 13, md: 20, sm: 13, lg: 20, xl: 20 },
                mx: 1,
                border: 1,
              }}
            />
          </>
        )}
        <Typography
          color="black"
          sx={{ fontWeight: "bold", ...(matches && { fontSize: 14 }) }}
          variant="h5"
        >
          {data?.title}
        </Typography>
        <Box sx={{ flex: 1 }} />
        <Box>
          <Typography
            component={"div"}
            sx={{ ...(matches && { fontSize: 10 }) }}
            color="black"
            variant="body1"
          >
            {data?.lectureName ??  data?.description}
          </Typography>
          <Typography
            component={"div"}
            color="black"
            sx={{ mt: 0.5 }}
            variant="caption"
          >
            {data?.eventName}
          </Typography>
          {data?.verse && <Typography
            component={"div"}
            color="black"
            sx={{ mt: 0.5 }}
            variant="body1"
          >
            {data?.verse}
          </Typography>}
        </Box>
      </Box>
    </>
  );
});

Header.displayName = "PlayerHeader";

const ST = ({ type, setDefaultPosition, currentPosition, data, id, verse }) => {
  return (
    <SubjectText
      type={type}
      onTitleClick={(value) => setDefaultPosition(value.startPosition)}
      position={currentPosition}
      titles={data?.topic || data?.lastTopic}
      lectureId={data?.lectureId}
      title={data?.title}
      verse={verse}
      mainId={data?.mainId}
      seriesId={type === "quran" ? id : data?.seriesId}
    />
  );
};

const Pl = ({
  defaultPosition,
  setCurrentPosition,
  data,
  type,
  id,
  verse,
  isMobile,
}) => {
  return (
    <PlayerControl
      defaultPosition={defaultPosition}
      onChangePosition={(value) => setCurrentPosition(value)}
      lecture={data}
      type={type}
      verse={verse}
      isMobile={isMobile}
      id={id}
    />
  );
};

const PlayerPage = ({ id, verse }) => {
  const [data, setData] = React.useState(null);
  const [series, setSeries] = React.useState(null);
  const [currentPosition, setCurrentPosition] = React.useState(null);
  const [defaultPosition, setDefaultPosition] = React.useState(0);
  const matches = useMediaQuery("(max-width:480px)");
  const location = useLocation();
  
  const { goto } = queryString.parse(location.search) || {};  

  React.useEffect(() => {
    if (data) {
      document.title = `${data?.title}`;
    }
  }, [data]);

  React.useEffect(() => {
    if (goto && +goto) {
      setDefaultPosition(+goto);
    }
  }, [setDefaultPosition, goto]);

  React.useEffect(() => {
    const fetch = async () => {
      const d = await getLectureByIdAsync({ lectureId: id });
      setData(d?.data);
    };
    if (id) fetch();
  }, [setData, id, setSeries]);

  return (
    <BaseLayout
      links={
        data
          ? [
              { title: title[data?.mainId], link: link[data?.mainId] },
              {
                title: data?.contextName || series?.title,
                link: `/series/${data?.seriesId || id}`,
              },
            ]
          : []
      }
      pageName={
        data &&
         `${dic.lecture} ${persianJs(data?.rowNumber)
          .digitsToWords()
          .toString()}`
      }
      topFrame={data && <Header matches={true} data={data} />}
    >
      {!matches && (
        <Box sx={{ display: "flex", minHeight: 100, p: 2 }}>
          <Box sx={{ flex: 1, px: 2 }}>
            {data && <Header data={data} />}
            <Box
              sx={{
                mt: 4,
              }}
            >
              <Pl
                defaultPosition={defaultPosition}
                setCurrentPosition={setCurrentPosition}
                data={data}
                id={id}
                verse={verse}
                isMobile={matches}
              />
            </Box>
          </Box>
          <PageDivider />
          <Box sx={{ flex: 1 }}>
            <Box sx={{ height: 80 }} />
            <Box
              sx={{
                mt: 4,
              }}
            >
              <ST
                setDefaultPosition={setDefaultPosition}
                currentPosition={currentPosition}
                data={data}
                id={id}
                verse={verse}
              />
            </Box>
          </Box>
        </Box>
      )}
      {matches && (
        <Box>
          <Box
            sx={{
              mt: 4,
            }}
          >
            <Box sx={{ px: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 } }}>
              {(data || id) && (
                <Pl
                  defaultPosition={defaultPosition}
                  setCurrentPosition={setCurrentPosition}
                  data={data}
                  verse={verse}
                  id={id}
                  isMobile={matches}
                />
              )}
            </Box>
            <Divider sx={{ my: 2, mx: 2 }} />

            <ST
              setDefaultPosition={setDefaultPosition}
              currentPosition={currentPosition}
              data={data}
              id={id}
              verse={verse}
            />
          </Box>
        </Box>
      )}
    </BaseLayout>
  );
};

export default PlayerPage;
