import React, { useEffect, useState, useCallback } from "react";
import BaseLayout from "~/Layout/Main.Layout";
import _ from "lodash";
import {
  AppBar,
  Box,  
  Divider,
  Tabs,
  Tab,
  Typography, 
  Dialog,
  DialogContent,
} from "@mui/material";
import { useLocation, navigate } from "@reach/router";
import queryString from "query-string";
import { search } from "~/modules/Search/searchCrud";
import persianJs from "persianjs";
import { Container } from "@mui/system";
import Search from "~/theme/components/search";
import dic from "~/data/dic";
import FastPlayer from "~/components/fast.player";
import SearchList from "./list";

const SearchPage = () => {
  const [result, setResult] = useState(null);
  const location = useLocation();
  const [newQuery, setQuery] = useState("");
  const [value, setValue] = useState(0);
  const [{ show, lectureId, startTime, text, endTime }, setPlayer] = useState({
    show: false,
    lectureId: null,
  });
  const { query } = queryString.parse(location.search) || {};

  const getCount = useCallback(
    (mainId) => {
      return _.filter(result?.list, (c) => c.mainId === mainId)?.length || 0;
    },
    [result]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13 && newQuery) {
        navigate(`/search/?query=${newQuery}`);
      }
    },
    [newQuery]
  );

  const handleChangeValue = useCallback(
    (_e, newValue) => {
      setValue(newValue);
    },
    [setValue]
  );

  const handleChange = useCallback(
    (event) => setQuery(event.target.value),
    [setQuery]
  );

  const handleFastPlayer = useCallback(
    ({ lectureId, startTime, text, endTime }) =>
      () =>
        setPlayer((prv) => ({
          show: !prv.show,
          lectureId,
          startTime,
          endTime,
          text,
        })),
    [setPlayer]
  );

  useEffect(() => {
    if (query) {
      setQuery(query);
      search({ query }, (_e, res) => {
        setResult(res?.data);
      });
    }
  }, [query, setResult, setQuery]);

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={show}
        onClose={handleFastPlayer({})}
      >
        <DialogContent>
          <FastPlayer
            defaultPosition={startTime}
            lectureId={lectureId}
            text={text}
            endTime={endTime}
          />
        </DialogContent>
      </Dialog>
      <BaseLayout pageName={`${dic.searchOnly}`}>
        <Box sx={{ minHeight: 100, px: 2, py: 3 }}>
          <>
            <AppBar
              elevation={0}
              color="default"
              position="sticky"
              sx={{
                top: { xs: 56, sm: 56, md: 82, lg: 82, xl: 82 },
                bgcolor: "rgba(249,249,249, 0.5)",
                backdropFilter: "blur(6px)",
                '-webkit-backdrop-filter':"blur(6px)",
              }}
            >
              <Container maxWidth="md">
                <Box sx={{ py: 1 }}>
                  <Search
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    value={newQuery}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      px: 2,
                      py: 1,
                    }}
                  >
                    <Typography variant="caption">
                      {dic.searchResult}:
                    </Typography>
                    <Typography sx={{ mx: 1 }} component={"p"} variant="body1">
                      {persianJs(result?.total?.value || "0")
                        .englishNumber()
                        .toString()}
                    </Typography>
                    <Typography variant="caption">{dic.searchItems}</Typography>
                    <Typography
                      sx={{ mx: 1, color: "primary.main" }}
                      component={"p"}
                      variant="body1"
                    >
                      {query}
                    </Typography>
                  </Box>
                  <Tabs value={value} onChange={handleChangeValue} variant="scrollable" scrollButtons="auto">
                    <Tab value={0} label={dic.all} />
                    <Tab value={1} label={`${dic.quran}(${getCount(1)})`} />
                    <Tab value={3} label={`${dic.nhj}(${getCount(3)})`} />
                    <Tab value={4} label={`${dic.sahifa}(${getCount(4)})`} />
                  </Tabs>
                  <Divider />
                </Box>
              </Container>
            </AppBar>
            <Container maxWidth="md">
              <SearchList
                value={value}
                onFast={handleFastPlayer}
                result={result}
              />
            </Container>
          </>
        </Box>
      </BaseLayout>
    </>
  );
};

export default SearchPage;
