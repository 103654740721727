import * as React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  ButtonBase,
  DialogTitle,
  IconButton,
} from "@mui/material";
import dic from "~/data/dic";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback } from "react";

const speed = [
  { title: "1x", value: 1 },
  { title: "1.25x", value: 1.25 },
  { title: "1.5x", value: 1.5 },
  { title: "2.0x", value: 2.0 },
];

const PlayerSetting = ({ show, onClose, currentSpeed = 1, onChangeSpeed }) => {
  const handleChangeSpeed = useCallback(
    (value) => () => {
      onChangeSpeed(value);
      onClose();
    },
    [onClose, onChangeSpeed]
  );

  return (
    <>
      <Dialog open={show} onClose={onClose}>
        <DialogTitle>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: 1 }} />
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 200, p: 0.5 }}>
            <Typography variant="caption">{dic.player.speed}</Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mt: 2,
                justifyContent: "center",
                flexDirection:"row-reverse"
              }}
            >
              {_.map(speed, (value) => {
                return (
                  <ButtonBase
                    onClick={handleChangeSpeed(value?.value)}
                    sx={{
                      width: 50,
                      height: 50,
                      bgcolor: "grey.50",
                      borderRadius: 3,
                      m: 1,
                      ...(currentSpeed === value?.value && {
                        bgcolor: "primary.main",
                        color: "white",
                      }),
                    }}
                    key={value.value}
                  >
                    <Typography>{value.title}</Typography>
                  </ButtonBase>
                );
              })}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PlayerSetting;
