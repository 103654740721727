import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import Progress from "@mui/material/CircularProgress";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import _ from "lodash";
import { useCallback } from "react";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const MainSlider = () => {
  const { sliders, device } = useSelector((state) => state.home);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = useCallback(
    (step) => {
      setActiveStep(step);
    },
    [setActiveStep]
  );

  return (
    <Box
      sx={{
        flex: 1,
        overflow: "hidden",
        borderRadius: 4,
      }}
    >
      <Box
        sx={{
          minHeight: { xs: 40, sm: 40, md: 80, lg: 200, xl: 200 },
          //  maxHeight: { xs: 180, md: 180, sm: 180, lg: 300, xl: 300 },
          bgcolor: "grey.300",
          borderRadius: 4,
          mb: 2,
          position: "relative",
          flex: 1,
          overflow: "hidden",
        }}
      >
        {!sliders && (
          <Box
            sx={{
              bgcolor: "grey.50",
              position: "absolute",
              left: "50%",
              top: "50%",
              marginLeft: "-45px",
              marginTop: "-45px",
              width: 90,
              height: 90,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9,
            }}
          >
            <Progress size={80} thickness={4} />
          </Box>
        )}
        {/* <Box
          sx={{           
            display:"flex",
            alignItems:"center",
            justifyContent: "center",
            p:1,
            height: { xs: 40, sm: 40, md: 60, lg: 60, xl: 60 },
          }}
        >
          <Typography sx={{fontWeight:"bold"}} variant="h6">جلسات تا عید غدیر تعطیل می باشد</Typography>
        </Box> */}
        {sliders && (
          <AutoPlaySwipeableViews
            axis={"x-reverse"}
            index={activeStep}
            interval={device?.duration || 10000}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {_.map(sliders, (slide, index) => (
              <Box
                component={slide?.link ? "a" : "div"}
                {...(slide?.link && { href: slide?.link })}
                key={`slider-${slide.sliderRow}-${index}`}
              >
                <Box
                  component="img"
                  sx={{
                    //height: 1 / 1,
                    display: "block",
                    overflow: "hidden",
                    objectFit: "cover",
                    maxWidth: 1 / 1,
                    width: 1 / 1,
                    // maxHeight: 120,
                  }}
                  src={slide?.src}
                  alt={slide?.deviceId}
                />
              </Box>
            ))}
          </AutoPlaySwipeableViews>
        )}
      </Box>
    </Box>
  );
};

export default MainSlider;
