import * as requestFromServer from "./playListCrud";
import { playListSlice, callTypes } from "./playListSlice";

const { actions } = playListSlice;

export const fetchPlayList = (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getList()
    .then((response) => {
      const list = response.data;
      dispatch(actions.playListFetched(list));
    })
    .catch((error) => {
      error.clientMessage = "Can't get playlist data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
