import { styled,alpha } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";

export const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: `${theme.spacing(3)} !important`,
  minWidth: 120,
  margin: "0 0 !important",
  border: 0,
  lineHeight: 0,
  color:"black",
  padding: theme.spacing(0.5, 0),
  background: "var(--main-color3)",
  transition: theme.transitions.create([
    "border-color",
    "background-color",
    "box-shadow",
  ]),
  "&.Mui-selected": {
    color: "white",
    background: "var(--main-color)",
  },
  "&:hover": {
    color: "white",
    background: "var(--main-color) !important",
    boxShadow: `${alpha(theme.palette.primary.main, 0.3)} 0 0 0 0.2rem`,
  },
  img: {
    height: 26,
  },
}));
