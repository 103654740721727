import { combineReducers } from "redux";
// import { all } from "redux-saga/effects";

//import * as auth from "~/modules/Auth/authRedux";
import { layoutSlice } from "~/modules/Layout/layoutRedux";
import { themeSlice } from "~/modules/Theme/themeRedux";
import { homeSlice } from "~/modules/Home/homeSlice";
import { liveSlice } from "~/modules/Live/liveSlice";
import { scheduleSlice } from "~/modules/Schedule/scheduleSlice";
import { lastSlice } from "~/modules/Last/lastSlice";
import { playListSlice } from "~/modules/PlayList/playListSlice";
import { callingCodesSlice, authSlice } from "~/modules/Auth/authSlice";

export const rootReducer = combineReducers({
  //auth: auth.reducer,
  layout: layoutSlice.reducer,
  theme: themeSlice.reducer,
  home: homeSlice.reducer,
  live: liveSlice.reducer,
  schedule: scheduleSlice.reducer,
  last: lastSlice.reducer,
  callingCodes: callingCodesSlice.reducer,
  auth: authSlice.reducer,
  playlist: playListSlice.reducer,
});

// export function* rootSaga() {
//   yield all([auth.saga()]);
// }
