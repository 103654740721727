import dic from "~/data/dic";
import { Box, Button, Divider } from "@mui/material";
import React from "react";

const NhjBar = ({ matches, level, onChange }) => {
  const handleChangeLevel = (newLevel) => () => onChange(newLevel);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          flex: 1,
          bgcolor: "var(--main-color)",
          height: 38,
          borderRadius: matches ? "8px 8px 0 0" : 4,
          mr: matches ? 0 : 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={handleChangeLevel(1)}
          sx={{ color: level === 1 ? "primary.main" : "white", minWidth: 100 }}
        >
          {dic.khotbe}
        </Button>
        <Divider
          orientation="vertical"
          sx={{ height: 25, borderColor: "white" }}
        />
        <Button
          onClick={handleChangeLevel(2)}
          sx={{ color: level === 2 ? "primary.main" : "white", minWidth: 100 }}
        >
          {dic.letter}
        </Button>
        <Divider
          orientation="vertical"
          sx={{ height: 25, borderColor: "white" }}
        />
        <Button
          onClick={handleChangeLevel(3)}
          sx={{ color: level === 3 ? "primary.main" : "white", minWidth: 100 }}
        >
          {dic.hekmat}
        </Button>
      </Box>
    </Box>
  );
};

export default NhjBar;
