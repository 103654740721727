import MainApi from "~/core/helper/apiHelper";
import { Api } from "~/core/helper/apiHelper";
import Url from "~/core/urls";

const { default: urlcat } = require("urlcat");
const securityApi = Api({ baseUrl: Url.security });

export function getCallingCodes() {
  return MainApi.asyncGet(urlcat(`callingCodes`));
}

export function getCode(data, callback) {
  return securityApi.post(urlcat(`appCode`), data, callback);
}

export function login(data, callback) {
  return securityApi.post(urlcat(`appLogin`), data, callback);
}

export function loginAsync(data) {
  return securityApi.post(urlcat(`appLogin`), data);
}

export function infoAsync() {
  return securityApi.asyncGet(urlcat(`data/appInfo`));
}


export function profile(data,callback) {
  return securityApi.put(urlcat(`data/profile`),data,callback);
}
