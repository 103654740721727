import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import dic from "~/data/dic";
import MonibVerticalDivider from "./vertical.divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ButtonBase } from "@mui/material";

const SearchBody = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  borderRadius: 4 * 4,
  padding: theme.spacing(0, 1, 0, 3),
  border: "3px solid var(--border-color)",
  [theme.breakpoints.down("md")]: {
    border: "2px solid var(--border-color)",
  },
  transition: theme.transitions.create([
    "border-color",
    "background-color",
    "box-shadow",
  ]),
  "&:hover": {
    boxShadow: `${alpha(theme.palette.primary.main, 0.3)} 0 0 0 0.1rem`,
    borderColor: theme.palette.primary.main,
  },
  // width: "100%",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  flex: 1,
  "& .MuiInputBase-input": {
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      color: theme.palette.text.primary,
      opacity: 1,
    },
  },
}));

const Search = ({ placeholder, onChange, onKeyDown, onSearch, value }) => {
  const matches = useMediaQuery("(max-width:480px)");
  return (
    <SearchBody sx={{ height: 38 }}>
      <StyledInputBase
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
        placeholder={placeholder || dic.search}
        inputProps={{ "aria-label": "search" }}
      />
      {!matches && <MonibVerticalDivider sx={{ height: 24, mx: 1 }} />}
      <ButtonBase sx={{ width: 22, height: 22 }} onClick={onSearch}>
        <Box
          component={"img"}
          src="/icons/find.svg"
          sx={{ maxHeight: 22, width: 22, height: 22 }}
        />
      </ButtonBase>
    </SearchBody>
  );
};

export default Search;
