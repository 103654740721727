import * as requestFromServer from "./homeCrud";
import { homeSlice, callTypes } from "./homeSlice";

const { actions } = homeSlice;

export const fetchHome = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getHomeData()
    .then((response) => {
      const list = response.data;
      dispatch(actions.homeFetched(list));
    })
    .catch((error) => {
      error.clientMessage = "Can't get home data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
