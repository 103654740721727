const { Box } = require("@mui/system");

const Frame = ({ sx, children }) => {
  return (
    <Box
      sx={{
        border: 3,
        borderRadius: 4,
        borderColor: "var(--border-color)",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Frame;
