import axios from "axios";
import Url from "~/core/urls";
import _ from "lodash";

const getUrl = (baseUrl, url) => `${baseUrl}${url}`;

export const resultFunc = (error, result, callback) => {
  if (_.isFunction(callback)) {
    let data = null;
    let err = null;
    if (result) {
      data = { status: result.status, data: result.data };
    }
    if (error) {
      err = {
        status: error?.response?.status,
        data: error?.response?.data,
      };
    }
    callback(err, data);
  }
};

export const Api = ({ baseUrl }) => {
  return {
    put: (url, data, callback) => {
      axios
        .put(getUrl(baseUrl, url), data)
        .then((result) => resultFunc(null, result, callback))
        .catch((err) => resultFunc(err, null, callback));
    },
    asyncPut: (url, data) => {
      return axios.put(getUrl(baseUrl, url), data);
    },
    post: (url, data, callback) => {
      axios
        .post(getUrl(baseUrl, url), data)
        .then((result) => resultFunc(null, result, callback))
        .catch((err) => resultFunc(err, null, callback));
    },
    asyncPost: (url, data) => {
      return axios.post(getUrl(baseUrl, url), data);
    },
    get: (url, callback) => {
      axios
        .get(getUrl(baseUrl, url))
        .then((result) => resultFunc(null, result, callback))
        .catch((err) => resultFunc(err, null, callback));
    },
    asyncGet: (url) => {
      return axios.get(getUrl(baseUrl, url));
    },
    delete: (url, callback) => {
      axios
        .delete(getUrl(baseUrl, url))
        .then((result) => resultFunc(null, result, callback))
        .catch((err) => resultFunc(err, null, callback));
    },
    deleteWithBody: (url, data, callback) => {
      axios
        .delete(getUrl(baseUrl, url), { data })
        .then((result) => resultFunc(null, result, callback))
        .catch((err) => resultFunc(err, null, callback));
    },
  };
};

export default Api({ baseUrl: Url.core });
