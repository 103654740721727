import * as React from "react";
import dic from "~/data/dic";
import { Box } from "@mui/material";
import ListLoading from "~/theme/components/loading";
import { getSeriesAsync } from "~/modules/series/seriesCrud";
import SoreList from "~/components/sore.list.v2";
//import useMediaQuery from "@mui/material/useMediaQuery";
import BaseLayout from "~/Layout/Main.Layout";

const getId = (type) => {
  switch (type) {
    case "quran":
      return 1;
    case "nahj-al-balagha":
      return 3;
    case "sahifa":
      return 4;
    case "sp":
      return 5;
    default:
      return 1;
  }
};

const titles = {
  quran: dic.quran,
  "nahj-al-balagha": dic.nhj,
  sahifa: dic.sahifa,
  sp: dic.sp,
};

const ContentPage = ({ id }) => {
  const [list, setList] = React.useState(null);
  //const matches = useMediaQuery("(max-width:480px)");

  React.useEffect(() => {
    const fetch = async () => {
      const list = await getSeriesAsync({ seriesId: getId(id) });
      setList(list?.data?.list);
    };
    if (id) fetch();
  }, [setList, id]);

  return (
    <BaseLayout pageName={id ? titles[id] : ""}>
      <Box sx={{ p: 2 }}>
        {!list && <ListLoading />}
        {list && (
          <>
            <SoreList list={list} type={id || "quran"} />
          </>
        )}
      </Box>
    </BaseLayout>
  );
};

export default ContentPage;
