import * as React from "react";
import BaseLayout from "~/Layout/Main.Layout";
import dic from "~/data/dic";
import { Box, Chip, Typography } from "@mui/material";
import _ from "lodash";
import PageDivider from "~/theme/components/page.divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fetchSchedule } from "~/modules/Schedule/scheduleAction";
import { useDispatch, useSelector } from "react-redux";

const BoxSchedule = ({
  title,
  address,
  time,
  displayDate,
  startDate,
  color = "type1",
  events,
  event,
  matches,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: 120,
        borderRadius: 4,
        bgcolor: color === "type1" ? "var(--main-color4)" : "transparent",
        borderColor: "text.primary",
        border: 2,
        mb: 1,
        px: 3,
        py: 2,
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", mb: 2 }}>
          {event && (
            <Chip
              sx={{
                color: "white",
                bgcolor: "var(--main-color)",
              }}
              label={event}
            />
          )}
          {_.map(events, (event, index) => (
            <Chip
              sx={{
                color: "white",
                ...(index % 2 === 0 && { bgcolor: "var(--main-color)" }),
                ...(index % 2 !== 0 && { bgcolor: "primary.main" }),
              }}
              key={index}
              label={event}
            />
          ))}
        </Box>
        <Typography
          sx={{
            fontWeight: "bold",
            ...(matches && {
              fontSize: 14,
            }),
          }}
          variant="h5"
        >
          {title}
        </Typography>
        <Typography
          sx={{
            ...(matches && {
              fontSize: 11,
            }),
          }}
          variant="subtitle1"
        >
          {address}
        </Typography>
      </Box>
      <PageDivider />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            ...(matches && {
              fontSize: 14,
            }),
          }}
          variant="h6"
        >
          {time}
        </Typography>
        {!!startDate && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ mr: 1, fontWeight: "bold" }}>از</Typography>
            <Typography
              variant="subtitle1"
              sx={{
                ...(matches && {
                  fontSize: 11,
                }),
              }}
            >
              {startDate}
            </Typography>
            <Typography sx={{ ml: 1, fontWeight: "bold" }}>تا</Typography>
          </Box>
        )}
        <Typography
          variant="subtitle1"
          sx={{
            ...(matches && {
              fontSize: 11,
            }),
          }}
        >
          {displayDate}
        </Typography>
      </Box>
    </Box>
  );
};

const SchedulePage = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function fetchData() {
      fetchSchedule()(dispatch);
    }
    fetchData();
  }, [dispatch]);

  const { active } = useSelector(({ schedule }) => schedule);

  const matches = useMediaQuery("(max-width:480px)");

  return (
    <BaseLayout links={[]} pageName={`${dic.schedule} `}>
      <Box
        sx={{
          display: "flex",
          minHeight: 100,
          p: 2,
          maxWidth: 600,
          margin: "auto",
        }}
      >
        <Box sx={{ flex: 1, textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", mb: 2 }} variant="h5">
            {dic.schedule}
          </Typography>
          {_.map(active, (item, index) => (
            <BoxSchedule
              key={index}
              {...item}
              matches={matches}
              color={index % 2 === 0 ? "type1" : "type2"}
            />
          ))}
        </Box>
      </Box>
    </BaseLayout>
  );
};

export default SchedulePage;
