const dic = {
  title: "منیب سامانه جامع آثار و سخنرانی‌های محمد علی انصاری",
  search: "جستجوی موضوعی ...",
  searchValue: "جستجو...",
  closeSearch:"بستن جستجو",
  searchOnly: "جستجو...",
  advanceSearch: "جستجوی پیشرفته",
  searchResult:"نتایج جستجو",
  searchItems:"مورد برای",
  all:"همه",
  quran: "تفسیر قرآن کریم",
  nhj: "شرح نهج البلاغه",
  sahifa: "شرح صحیفه سجادیه",
  textQuran: "متن قرآن",
  textNhj: "متن نهج البلاغه",
  textSahifa: "متن صحیفه سجادیه",
  sp: "گفتارهای ویژه",
  podcast: "پادکست",
  schedule: "برنامه‌ها",
  last: "آخرین سخنرانی‌ها",
  wisdom: "نکته‌ها و حکمت‌ها",
  myPlayList: "فهرست من",
  createdPlayList:"تاریخ ایجاد:",
  lastView: "آخرین دیده شده‌ها ",
  playlistTitle:"عنوان",
  support: "پشتیبانی",
  live: "پخش زنده",
  toLive: "تا شروع پخش:",
  hour: "ساعت",
  homePage: "صفحه اصلی",
  lecture: "جلسه",
  lectures: "جلسات",
  time: "زمان:",
  subject: "موضوعات",
  quranText: "متن قرآن",
  sound: "صوتی",
  video: "تصویری",
  khotbe: "خطبه‌ها",
  letter: "نامه‌ها",
  hekmat: "حکمت‌ها",
  pray:"دعای",
  nhj_t: "نهج‌البلاغه",
  sahifa_t: "صحیفه‌سجادیه",  
  sp_t: "گفتارهای ویژه",  
  archiveSchedule: "آرشیو زمانی برنامه های پخش شده",
  remainTime: "زمان باقیمانده تا شروع پخش زنده",
  showAll: "نمایش همه",
  addPlaylist: "افزودن فهرست پخش جدید",
  quickPlay: "پخش سریع",
  profile: "پروفایل",
  required: "الزامی",
  optional: "اختیاری",
  tel: "شماره تماس",
  email: "ایمیل",
  or: "یا",
  fullName: "نام و نام‌خانوادگی",
  city: "شهر",
  country: "کشور",
  education: "تحصیلات",
  verse: "آیه",
  page: "صفحه",
  share:"اشتراک گذاری",
  terminology:"واژه‌کاوی",
  login:"ورود",
  lectureCount:"جلسه",
  mobile:"شماره همراه",
  goToPage:"رفتن به صفحه.",
  order:"ترتیب نمایش",
  fastPlayer:"پخش سریع",
  activeCode:"کد فعال سازی",
  required_mobile:"موبایل را به طور صحیح وارد نمایید",
  required_code:"کد فعال سازی را به طور صحیح وارد نمایید",  
  active_code_is_wrong:"کد فعال سازی اشتباه می باشد",
  register:"ثبت نام",
  logout:"خروج",
  update:"به روز رسانی",
  updateProfile:"اطلاعات کاربری به روز شد",
  changeMobile:"تغییر شماره همراه",
  getActiveCode:"دریافت کد فعال سازی",
  ok:"تایید",
  cancel:"انصراف",
  enterTitle:"عنوان را وارد نمایید",
  deletedPlaylist:"مایل به حذف گزینه انتخاب شده می باشید؟",
  needToLogin:"برای استفاده از این بخش لطفا وارد شوید",
  of:"از",
  rowOnPage:"نمایش",
  player:{
    speed:"سرعت پخش"
  },
  nextLecture:"جلسه بعدی",
  previousLecture:"جلسه قبلی",
  isNotFinished:(mainId)=>`در حال تفسیر`,
  lastCourse:(mainId)=>`دور پیشین تفسیر`,
  newCourse:(mainId)=>`دور جدید تفسیر`,
};

export default dic;
