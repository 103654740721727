import * as React from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import dic from "~/data/dic";
import { Box, Typography } from "@mui/material";
import { CustomToggleButton } from "~/theme/components/custom.toggle";
import SubjectList from "./subject.list";
import Quran from "~/components/quran";

const SubjectText = ({
  titles,
  position,
  onTitleClick,
  title,
  lectureId,
  seriesId,
  type,
  verse,
  mainId,
}) => {
  const [state, setState] = React.useState(
    type === "quran" ? "text" : "subject"
  );

  React.useEffect(() => {
    setState(type === "quran" ? "text" : "subject");
  }, [type, setState]);

  const handleSate = (_event, newState) => {
    setState(newState);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {mainId === 1 && (
          <ToggleButtonGroup
            value={state}
            exclusive
            onChange={handleSate}
            aria-label="player state"
          >
            <CustomToggleButton value={"subject"}>
              <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                {dic.subject}
              </Typography>
            </CustomToggleButton>
            <CustomToggleButton value={"text"}>
              <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                {dic.quranText}
              </Typography>
            </CustomToggleButton>
          </ToggleButtonGroup>
        )}
      </Box>
      <Box sx={{ mt: 2, px: { xl: 4, lg: 4, md: 4, sm: 1, xs: 1 }, mb: 6 }}>
        {state === "subject" && (
          <SubjectList
            title={title}
            onClick={onTitleClick}
            position={position}
            list={titles}
            lectureId={lectureId}
          />
        )}
        {state === "text" && (
          <Box sx={{ px: 2 }}>
            {mainId === 1 && (
              <Quran seriesId={seriesId} selectedVerse={verse} />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default SubjectText;
