import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors/";

const fontSizes = {
  small: 10,
  normal: 12,
  medium: 14,
  large: 16,
  veryLarge: 18,
};

const Theme = ({ fontSize, mode }) =>
  createTheme({
    direction: "rtl",
    typography: {
      useNextVariants: true,
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "IRANSans",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: fontSizes[fontSize],
    },
    palette: {
      mode,
      primary: { light: "#86e0e6", main: "#40ba8d", dark: "#21825a" },
      secondary: { main: "#ed1847", dark: "#af0c1d" },
      text: {
        primary: "#2d3d35",
        // secondary: "#af0c1d",
        // disabled: "rgba(255,255,255,0.5)",
        // hint: "#b7bdbb",
      },
      error: {
        main: red.A400,
      },
      background: {
        paper: "#fff",
        default: "#fff",
      },
    },
    shape: {
      borderRadius: 4,
    },
    overrides: {},
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: "20px",
          },
        },
      },
      MuiButtonGroup: {
        defaultProps: {
          disableElevation: true,
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: ({ ownerState }) => ({
            ...(!ownerState.fullScreen && {
              borderRadius: "20px",
            }),
          }),
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },

      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: "20px",
          },
        },
      },

      MuiButton: {
        variants: [
          {
            props: { variant: "contained" },
            style: () => ({
              color: "white",
            }),
          },
        ],
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: ({ variant }) => ({
            borderRadius: "20px",
            ...(variant === "contained" && { color: "white" }),
          }),
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.spacing(5),
            border: `2px solid var(--border-color)`,
            fontSize: 16,
            padding: theme.spacing(0.5),
            "& .MuiSelect-select": {
              padding: "4px 12px",
            },
          }),
        },
      },
    },
  });

export default Theme;
