import React, { useEffect } from "react";
import BaseLayout from "~/Layout/Main.Layout";
import dic from "~/data/dic";
import { Box, Button, Container } from "@mui/material";
import MonibTextField from "~/theme/components/textField";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCallingCodes,
  authLogout,
  fetchAuth,
} from "~/modules/Auth/authAction";
import { getCode, login, profile } from "~/modules/Auth/authCrud";
import _ from "lodash";
import { useState } from "react";
import { useCallback } from "react";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";
import MobilePart from "./mobile";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import { navigate } from "@reach/router";

const initialValue = {
  cioc: "",
  country: "",
  mobile: "",
  fullName: "",
  education: "",
  city: "",
  cca2: "",
  userId: 0,
};

const ProfilePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCallingCodes());
  }, [dispatch]);

  const { list, current } = useSelector((state) => state.callingCodes);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mode, setMode] = useState("mobile");
  const { userData } = useSelector(({ auth }) => auth);

  const [
    {
      cioc,
      country,
      mobile,
      fullName,
      education,
      city,
      cca2,
      activeCode,
      code,
      userId,
    },
    setValue,
  ] = useState(initialValue);

  useEffect(() => {
    if (current) {
      setValue((prv) => ({
        ...prv,
        cioc: current?.data?.cioc,
        country: current?.data?.fa,
        cca2: current?.data?.cca2,
        code: current?.code,
      }));
    }
  }, [current, setValue]);

  const handleChange = useCallback(
    (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setValue((prv) => ({
        ...prv,
        [name]: value,
        ...(name === "cioc" && {
          cca2: _.find(list, (v) => v.cioc === value)?.cca2,
          code: _.find(list, (v) => v.cioc === value)?.code,
        }),
      }));
    },
    [setValue, list]
  );

  const handleActiveCode = useCallback(() => {
    setIsSubmit(true);
    setIsLoading(true);
    setErrorMessage("");
    if (activeCode && activeCode.length === 4) {
      login({ userId, code: activeCode }, (error, res) => {
        if (error) {
          setErrorMessage(dic.active_code_is_wrong);
        } else {
          dispatch(fetchAuth(res?.data));
          navigate("/");
        }
      });
    }
  }, [
    dispatch,
    activeCode,
    setIsSubmit,
    userId,
    setIsLoading,
    setErrorMessage,
  ]);

  const submit = useCallback(() => {
    setIsSubmit(true);
    if (mobile && isValidPhoneNumber(mobile, cca2)) {
      getCode(
        { cioc, country, mobile, fullName, education, city, cca2, code },
        (_e, res) => {
          setValue((prv) => ({ ...prv, userId: res?.data?.id }));
        }
      );
      setMode("code");
      setIsSubmit(false);
    }
  }, [
    cioc,
    country,
    mobile,
    fullName,
    education,
    city,
    cca2,
    code,
    setMode,
    setIsSubmit,
  ]);

  const handleMessage = useCallback(
    () => setOpenMessage((prv) => !prv),
    [setOpenMessage]
  );

  const handleUpdate = useCallback(() => {
    setIsLoading(true);

    profile(
      { cioc, country, fullName, education, city, cca2, code },
      (_e, res) => {
        setValue((prv) => ({ ...prv, userId: res?.data?.id }));
        setIsLoading(false);
        handleMessage();
      }
    );
    setIsLoading(true);
  }, [
    cioc,
    country,
    fullName,
    education,
    city,
    cca2,
    code,
    setIsLoading,
    handleMessage,
  ]);

  useEffect(() => {
    if (mode === "mobile") {
      setErrorMessage("");
      setIsSubmit(false);
      setValue((prv) => ({ ...prv, activeCode: "" }));
    }
  }, [mode, setErrorMessage, setIsSubmit, setValue]);

  const handelChangeMode = useCallback(
    (newMode) => () => {
      setMode(newMode);
    },
    [setMode]
  );

  const handelLogout = useCallback(() => {
    dispatch(authLogout);
    setValue(initialValue);
  }, [dispatch, setValue]);

  useEffect(() => {
    setValue(userData);
  }, [userData, setValue]);

  return (
    <>
      <Snackbar
        open={openMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleMessage}
        message={dic.updateProfile}
      />
      <BaseLayout pageName={`${dic.profile}`}>
        <Container maxWidth={"sm"}>
          <Box
            sx={{
              display: "flex",
              minHeight: 100,
              pb: 4,
              px: 0,
              flexDirection: "column",
            }}
          >
            {!userData?.userId && (
              <Box sx={{ flex: 1, px: { xs: 0, sm: 0, md: 2, lg: 2, xl: 2 } }}>
                <MobilePart
                  mode={mode}
                  onChange={handleChange}
                  isSubmit={isSubmit}
                  activeCode={activeCode}
                  errorMessage={errorMessage}
                  mobile={mobile}
                  cca2={cca2}
                  onResend={submit}
                  cioc={cioc}
                  onActiveCode={handleActiveCode}
                  onChangeMode={handelChangeMode}
                  onSubmit={submit}
                />
              </Box>
            )}
            <Box
              sx={{
                flex: 1,
                px: {
                  xs: 1,
                  sm: 1,
                  md: 4,
                  lg: 4,
                  xl: 4,
                },
              }}
            >
              {/* <Typography
                sx={{ fontWeight: "bold", color: "primary.dark" }}
                variant="h6"
              >
                {dic.register}
              </Typography> */}
              {mode === "mobile" && (
                <Box sx={{ mt: 5 }}>
                  <MobilePart
                    mode={mode}
                    onChange={handleChange}
                    isSubmit={isSubmit}
                    activeCode={activeCode}
                    errorMessage={errorMessage}
                    mobile={mobile || userData?.mobile}
                    isReadOnly={userData?.mobile}
                    cca2={cca2}
                    onResend={submit}
                    cioc={cioc}
                    onActiveCode={handleActiveCode}
                    onChangeMode={handelChangeMode}
                    onSubmit={submit}
                    showAction={false}
                  />

                  <>
                    <Box sx={{ mt: 3 }}>
                      <MonibTextField
                        onChange={handleChange}
                        name="fullName"
                        value={fullName}
                        label={dic.fullName}
                      />
                    </Box>
                    {/* <Box sx={{ mt: 4 }}>
                    <MonibTextField
                      onChange={handleChange}
                      name="country"
                      value={country}
                      label={dic.country}
                    />
                  </Box>
                  <Box sx={{ mt: 4 }}>
                    <MonibTextField
                      onChange={handleChange}
                      name="city"
                      value={city}
                      label={dic.city}
                    />
                  </Box> */}

                    <Box
                      sx={{
                        display: "flex",
                        mt: 2,
                        justifyContent: "space-between",
                        flexDirection: {
                          xs: "column",
                          sm: "column",
                          md: "row",
                          lg: "row",
                          xl: "row",
                        },
                      }}
                    >
                      <LoadingButton
                        onClick={userData?.userId ? handleUpdate : submit}
                        sx={{ minWidth: 120 }}
                        loading={isLoading}
                        variant="contained"
                      >
                        {!userData?.userId ? "ثبت نام" : dic.update}
                      </LoadingButton>
                      {userData?.userId && (
                        <Button
                          sx={{
                            minWidth: 120,
                            mt: {
                              xs: 1,
                              sm: 1,
                              md: 0,
                              lg: 0,
                              xl: 0,
                            },
                          }}
                          color="secondary"
                          variant="contained"
                          onClick={handelLogout}
                        >
                          {dic.logout}
                        </Button>
                      )}
                    </Box>
                  </>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </BaseLayout>
    </>
  );
};

export default ProfilePage;
