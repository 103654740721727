import React from "react";
import _ from "lodash";
import { Box, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Link } from "@reach/router";
import Search from "~/theme/components/search";
import dic from "~/data/dic";
import NhjBar from "~/components/njh.bar";

const SoreList = ({ list, type = "quran" }) => {
  const [value, setValue] = React.useState("");
  const [level, setLevel] = React.useState(1);

  const handleFind = React.useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue]
  );

  const getList = () => {
    return _.filter(
      list,
      (v) =>
        (type === "nahj-al-balagha" ? v.type === level : true) &&
        (value === "" ||
          value.length === 0 ||
          v.title.indexOf(value) > -1 ||
          v.rowId === +value)
    );
  };

  const currentList = getList(list);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          position: "sticky",
          top: { xs: 54, sm: 54, md: 80, lg: 80, xl: 80 },
          borderRadius: 5,
          bgcolor: "rgba(249,249,249, 0.5)",
          backdropFilter: "blur(10px)",
          "-webkit-backdrop-filter": "blur(10px)",
          justifyContent: {
            xl: "flex-start",
            lg: "flex-start",
            md: "center",
            sm: "center",
            xs: "center",
          },
          zIndex: 999,
          mb: 2,
          px: { xs: 0, md: 3, sm: 0, lg: 3, xl: 3 },
          py: 1,
        }}
      >
        {type === "nahj-al-balagha" && (
          <NhjBar
            matches={false}
            level={level}
            onChange={(level) => setLevel(level)}
          />
        )}
        {(type === "quran" || type==="sp") && (
          <Search placeholder={dic.searchOnly} onChange={handleFind} />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          pb: { xs: 4, md: 0, sm: 4, lg: 0, xl: 0 },
          flexDirection: {
            xs: "column",
            md: "row",
            sm: "row",
            lg: "row",
            xl: "row",
          },
        }}
      >
        {_.map(
          _.chunk(
            currentList,
            value === ""
              ? currentList.length / 3 + (currentList.length % 3 === 0 ? 0 : 1)
              : 1000
          ),
          (l, index) => {
            return (
              <Box key={index} sx={{ flex: 1 }}>
                {_.map(l, (value) => {
                  return (
                    <React.Fragment key={`item-${value.id}`}>
                      <Box
                        sx={{
                          px: { xs: 0, md: 3, sm: 3, lg: 3, xl: 3 },
                        }}
                      >
                        <Link
                          to={
                            value?.lectureCount === 1
                              ? `/player/${value.lectureId}`
                              : `/series/${value.id}`
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              px: 2,
                              py: 2,
                              width: 1 / 1,
                              alignItems: "flex-start",
                              position: "relative",
                              color: "text.primary",
                              ...((type === "nahj-al-balagha" ||
                                type === "sahifa" || type === "sp") && {
                                minHeight: 80,
                              }),
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                width: 1 / 1,
                                alignItems: "center",
                              }}
                            >
                              {type === "quran" && (
                                <Typography
                                  variant="h6"
                                  component={"span"}
                                  sx={{ mx: 1 }}
                                >
                                  {value.displayRow}
                                </Typography>
                              )}
                              <Typography
                                sx={{
                                  flex: 1,
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {value.title}
                              </Typography>
                              <Box sx={{ flexBasis: 60 }} />
                              <Typography sx={{ color: "text.primary" }}>
                                {value.description}
                              </Typography>
                            </Box>
                            <Typography
                              component="span"
                              sx={{ mt: 0.5, color: "text.primary" }}
                            >
                              {value.secondary}
                            </Typography>
                          </Box>
                        </Link>
                        <Divider />
                      </Box>
                    </React.Fragment>
                  );
                })}
              </Box>
            );
          }
        )}
      </Box>
    </>
  );
};

export default SoreList;
