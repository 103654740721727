import { AppBar, Box, ButtonBase, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import UserIcon from "~/assets/icons/user-light.svg";
import MSwipeableDrawer from "@mui/material/SwipeableDrawer";
import React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

const Fr = styled(Box)({
  border: "2px solid white",
  height: 40,
  borderRadius: 4 * 3,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 40,
  "& img": {
    width: 24,
    height: 24,
  },
});

const SwipeableDrawer = styled(MSwipeableDrawer)(({ theme }) => ({
  ".MuiDrawer-paper": {
    background: "rgba(45, 61, 53,0.8)",
    backdropFilter: "blur(8px)",
    width: "calc(100% - 20px)",
    left: 10,
    borderRadius: theme.spacing(4, 4, 0, 0),
  },
}));

const Key = styled(Box)(({ theme }) => ({
  width: 40,
  flex: "0 0 40px",
  margin: theme.spacing(0, 1),
}));

//https://shop.monibapp.co.uk/

const MobileAppbar = ({ uk }) => {
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => () => {
    setState(open);
  };

  return (
    <>
      <SwipeableDrawer
        anchor={"bottom"}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{}}
      >
        <Box
          sx={{
            p: 2,
            py: 4,
            a: {
              textDecoration: "none",
              color: "white",
              fontWeight: "bold",
            },
          }}
        >
          <List sx={{ width: "100%", maxWidth: 360 }}>
            <Link href="https://hedayatnoor.org/main/prof" target="_blank">
              <ListItem>
                <ListItemAvatar>
                  <Box
                    component={"img"}
                    sx={{ width: 30, height: 30 }}
                    src="/icons/prof.svg"
                  ></Box>
                </ListItemAvatar>
                <ListItemText primary="محمدعلی انصاری" secondary="" />
              </ListItem>
            </Link>

            <Link href="https://shop.hedayatnoor.com/" target="_blank">
              <ListItem>
                <ListItemAvatar>
                  <Box
                    component={"img"}
                    sx={{ width: 30, height: 30 }}
                    src="/icons/book.svg"
                  ></Box>
                </ListItemAvatar>
                <ListItemText primary="فروشگاه کتاب" secondary="" />
              </ListItem>
            </Link>
            <Link href="https://t.me/MohammadAliAnsari" target="_blank">
              <ListItem>
                <ListItemAvatar>
                  <Box
                    component={"img"}
                    sx={{ width: 30, height: 30 }}
                    src="/icons/teleg.svg"
                  ></Box>
                </ListItemAvatar>
                <ListItemText primary="کانال تلگرام" secondary="" />
              </ListItem>
            </Link>

            <Link href="https://eitaa.com/hedayatnoor" target="_blank">
              <ListItem>
                <ListItemAvatar>
                  <Box
                    component={"img"}
                    sx={{ width: 30, height: 30 }}
                    src="/icons/eata.svg"
                  ></Box>
                </ListItemAvatar>
                <ListItemText primary="کانال ایتا" secondary="" />
              </ListItem>
            </Link>
            <Link
              href="https://www.instagram.com/hedayatnoor/?hl=fa"
              target="_blank"
            >
              <ListItem>
                <ListItemAvatar>
                  <Box
                    component={"img"}
                    sx={{ width: 30, height: 30 }}
                    src="/icons/insta.svg"
                  ></Box>
                </ListItemAvatar>
                <ListItemText primary="صفحه اینستاگرام" secondary="" />
              </ListItem>
            </Link>
            <Link href="https://hedayatnoor.com/" target="_blank">
              <ListItem>
                <ListItemAvatar>
                  <Box
                    component={"img"}
                    sx={{ width: 30, height: 30 }}
                    src="/icons/site.svg"
                  ></Box>
                </ListItemAvatar>
                <ListItemText
                  primary="وبسایت موسسه بیان هدایت نور"
                  secondary=""
                />
              </ListItem>
            </Link>
          </List>
        </Box>
      </SwipeableDrawer>
      <AppBar
        sx={{
          bottom: 0,
          top: "auto",
          width: "calc(100% - 10px)",
          right: 5,
          borderRadius: (theme) => theme.spacing(2, 2, 0, 0),
          background: "url(/images/mobile-appbar-back.jpg)",
          color: "white",
          boxShadow: 10,
        }}
        position="fixed"
      >
        <Box
          sx={{
            height: 60,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 3.5,
          }}
        >
          <Link to={"/"}>
            <Key>
              <Fr>
                <Box component={"img"} src="/icons/home-light.svg" />
              </Fr>
            </Key>
          </Link>
          <Link to={"/user/playlist"}>
            <Key>
              <Fr>
                <Box component={"img"} src="/icons/myPlayList-light.svg" />
              </Fr>
            </Key>
          </Link>
          <Key>
            <ButtonBase
              onClick={toggleDrawer(true)}
              sx={{
                border: "2px solid white",
                height: 40,
                borderRadius: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 40,
                "& img": {
                  width: 24,
                  height: 24,
                },
              }}
            >
              <Box component={"img"} src="/icons/menu-light.svg" />
            </ButtonBase>
          </Key>
          <Link to={"/profile"}>
            <Key>
              <Fr>
                <Box component={"img"} src={UserIcon} />
              </Fr>
            </Key>
          </Link>
          <Link to={"/support"}>
            <Key>
              <Fr>
                <Box component={"img"} src={"/icons/support.svg"} />
              </Fr>
            </Key>
          </Link>
          {/* <Key disabled>
          <Fr>
            <Box component={"img"} src="/icons/menu-light.svg" />
          </Fr>
        </Key>
        <Key disabled>
          <Fr>
            <Box component={"img"} src="/icons/download-light.svg" />
          </Fr>
        </Key>
        <Key disabled>
          <Fr>
            <Box component={"img"} src="/icons/support-light.svg" />
          </Fr>
        </Key> */}
        </Box>
      </AppBar>
    </>
  );
};

export default MobileAppbar;
