import * as React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  DialogTitle,
  IconButton,
  Slider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";

const theme = createTheme({
  direction: "lrt",
  palette: {
    mode: "light",
    primary: { light: "#86e0e6", main: "#40ba8d", dark: "#40ba8d" },
  },
});

const VolumeSetting = ({ show, onClose, volume, setVolume }) => {
  const sliderChange = React.useCallback(
    (_e, value) => setVolume(value),
    [setVolume]
  );

  return (
    <>
      <Dialog open={show} onClose={onClose}>
        <DialogTitle>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: 1 }} />
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minWidth: { xs: 200, sm: 200, md: 300 },
              p: 0.5,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton onClick={() => setVolume(100)}>
              <VolumeUpIcon />
            </IconButton>
            <ThemeProvider theme={theme}>
              <Slider
                onChange={sliderChange}
                size="small"
                max={100}
                value={volume}
                sx={{
                  flex: 1,
                  mx: 2,
                  "& .MuiSlider-thumb": {
                    background: "var(--main-color)",
                    transform: "translate(50%, -50%)",
                  },
                  "& .MuiSlider-track": {
                    background: "var(--main-color)",
                  },
                }}
              />
            </ThemeProvider>
            <IconButton onClick={() => setVolume(0)}>
              <VolumeDownIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VolumeSetting;
