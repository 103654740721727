import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import BaseLayout from "~/Layout/Main.Layout";
import dic from "~/data/dic";
import { navigate } from "@reach/router";

const UserLayout = ({ children }) => {
  const { userData } = useSelector(({ auth }) => auth);

  //const page = children?.props?.location?.pathname;
  //let text ="برای استفاده از فهرست من(Playlist) لطفا وارد شوید";
  //let type = "playlist";

  // switch (page) {
  //   case "/user/playlist":
  //     //type = "playlist";
  //     text ="برای استفاده از فهرست من لطفا وارد شوید";
  //     break;
  //   default:
  //    // type = "playlist";
  //     text ="برای استفاده از فهرست من لطفا وارد شوید";
  //     break;
  // }

  return (
    <>
      {userData?.userId ? (
        children
      ) : (
        <BaseLayout>
          <Box
            sx={{
              p: 2,
              minHeight: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mr: 2,
                mb: { xs: 2, sm: 2, md: 0, lg: 0, xl: 0 },
                textAlign: "center",
              }}
            >
              برای استفاده از فهرست من (Playlist)
              
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mr: 2,
                mb: { xs: 2, sm: 2, md: 0, lg: 0, xl: 0 },
                textAlign: "center",
              }}
            >
              لطفا وارد شوید
              
            </Typography>
            <Button
              onClick={() => navigate("/profile")}
              sx={{ minWidth: 120 }}
              variant="contained"
            >
              {dic.login}
            </Button>
          </Box>
        </BaseLayout>
      )}
    </>
  );
};

export default UserLayout;
