//import moment from "moment";
//import jlMoment from "moment-jalaali";
import _ from "lodash";

//jlMoment.loadPersian({ dialect: "persian-modern" });

const useDate = () => {
  const dateFormat = ({
    date,
    format = "jYYYY/jM/jD ، HH:mm",
    onlyPast = false,
    onlyDate = false,
  } = {}) => {
    const isPersian = true;
    if (!isPersian) {
      format = _.replace(format, /j/g, "");
    }

    const m = isPersian ? require("moment-jalaali") : require("moment");

    if (isPersian) {
      m.loadPersian({ dialect: "persian-modern" });
    }

    if (onlyPast) {
      return m(date).startOf("second").fromNow();
    }

    if (onlyDate) {
      return `${format !== "" ? `${m(date).format(format)}` : ""}`;
    }

    return `${format !== "" ? `${m(date).format(format)} ،` : ""} ${m(date)
      .startOf("second")
      .fromNow()}`;
  };

  return {
    dateFormat,
  };
};

export default useDate;
