import MainSlider from "~/components/slider";
import dic from "~/data/dic";
import { Box, Container } from "@mui/material";
import Search from "~/theme/components/search";
import MainButtons from "~/components/main.buttons";
import Items, { Item, Live } from "~/components/items";
import Footer from "~/components/footer";
import MainMenu from "~/components/main.menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import AdvancedSearch from "~/theme/components/advanced.search";
import MobileAppbar from "~/components/mobile.app.bar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchHome } from "~/modules/Home/homeAction";
import { useCallback } from "react";
import { useState } from "react";
import { navigate } from "@reach/router";
import { QuranMonib } from "../../components/items";

function Home() {
  const matches = useMediaQuery("(max-width:480px)");
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [uk, setUk] = useState(false);
  const { count, isLive, liveCounter } = useSelector(({ home }) => home);

  useEffect(() => {
    async function fetchData() {
      fetchHome()(dispatch);
    }
    fetchData();
  }, [dispatch]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13 && query) {
        navigate(`/search/?query=${query}`);
      }
    },
    [query]
  );

  const handleSearch = useCallback(() => {
    if (query) {
      navigate(`/search/?query=${query}`);
    }
  }, [query]);

  const handleChange = useCallback(
    (event) => setQuery(event.target.value),
    [setQuery]
  );

  useEffect(() => {
    const uk = window.location.href?.indexOf("monibapp.co.uk") > -1;
    setUk(uk);
  }, []);

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!matches && (
          <Container sx={{ pt: 1, position: "relative" }} maxWidth="md">
            {!uk && <MainSlider />}
            
            <Box sx={{ display: "flex", mt: 2.0, position: "relative" }}>
              <MainMenu />
              <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Search
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  onSearch={handleSearch}
                />
                <MainButtons count={count} />
              </Box>
              <Box sx={{ flex: "0 0 345px", pl: 1.0 }}>
                <Items isLive={isLive} liveCounter={liveCounter} uk={uk} />
              </Box>
            </Box>
            <Footer />
          </Container>
        )}
        {matches && (
          <Container sx={{ pt: 1, position: "relative" }} maxWidth="sm">
            <MobileAppbar />
            {!uk && <MainSlider />}
            <Box sx={{ flex: 1, mb:0.5 }}>
              <QuranMonib />
            </Box>
            <Box sx={{ display: "flex", width: 1 / 1 }}>
              <Box sx={{ flex: 1 }}>
                <Search
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  onSearch={handleSearch}
                />
              </Box>
              <Box sx={{ flex: "0 0 130px", pl: 0.5 }}>
                <AdvancedSearch />
              </Box>
            </Box>
            <Box
              sx={{
                pb: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <MainButtons count={count} uk={uk} isMobile />
              <Box
                sx={{
                  display: "flex",
                  width: 1 / 1,
                  pb: 0.5,
                  justifyContent: "space-between",
                  "&> a": { flex: 1, ml: 0.5 },
                  "&> a:first-of-type": { ml: 0 },
                }}
              >
                {!uk && (
                  <Item
                    isTop
                    icon="/icons/schedule.svg"
                    link="/schedule"
                    title={dic.schedule}
                  />
                )}
                <Item
                  isTop
                  icon="/icons/last.svg"
                  link="/last"
                  title={dic.last}
                />
                {/* <Item
                  isTop
                  icon="/icons/view.svg"
                  link="/user/last-view"
                  title={dic.lastView}
                /> */}
                <Item
                  isTop
                  icon="/icons/wisdom.svg"
                  link="/wisdom"
                  //link="/underConstruction"
                  title={dic.wisdom}
                />
                {/* <Item
                  isTop
                  icon="/icons/schedule.svg"
                  link="/schedule"
                  title={dic.schedule}
                /> */}
                {/* <Item
                  isTop
                  icon="/icons/support.svg"
                  link="/support"
                  title={dic.support}
                /> */}
              </Box>

              <Live isLive={isLive} liveCounter={liveCounter} />
            </Box>
          </Container>
        )}
      </Box>
    </>
  );
}

export default Home;
