import { Router } from "@reach/router";
import HomePage from "~/Pages/Home";
import ContentPage from "~/Pages/Content";
import SeriesPage from "~/Pages/Series";
import Player from "~/Pages/Player/";
import Live from "~/Pages/live";
import Schedule from "~/Pages/schedule";
import LastLecture from "~/Pages/last";
import Wisdom from "~/Pages/wisdom";
import PlayList from "~/Pages/Playlist/";
import LastView from "~/Pages/last-view";
import Support from "~/Pages/support";
import Profile from "~/Pages/Profile/";
import QuranPlayerPage from "./Pages/Player/quran";
import UnderConstruction from "./Pages/under.construction";
import Search from "./Pages/Search/";
import { useLocation } from "@reach/router";
import { useEffect } from "react";
import _ from "lodash";
import dic from "~/data/dic";
import axios from "axios";
import { setupAxios } from "./redux";
import { useDispatch } from "react-redux";
import Splash from "./Layout/Splash";
import UserLayout from "./Layout/UserLayout";
import { addVisit } from "./modules/Stat/statCrud";
import ReactGA from "react-ga";

ReactGA.initialize("G-5N5Y4FPWEY");

function App({ store }) {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname && !_.startsWith(location.pathname, "/player")) {
      document.title = dic.title;
    }

    addVisit({
      page: location?.pathname,
      hostname: window.location.hostname,
      query: location.search,
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  useEffect(() => {
    setupAxios(axios, store, dispatch);
    // setupAxios(axios, store, dispatch);
  }, [dispatch, store]);

  return (
    <Router>
      <Splash path="/">
        <ContentPage path="content/:id" />
        <SeriesPage path="series/:id" />
        <Player path="player/:id" />
        <QuranPlayerPage path="quran-player/:id/:verse" />
        <Live path="live" />
        <Schedule path="schedule" />
        <LastLecture path="last" />
        <Wisdom path="wisdom/:shareId" />
        <Wisdom path="wisdom" />

        <UserLayout path="user">
          <PlayList path="playlist" />
          <LastView path="last-view" />
        </UserLayout>

        <Support path="support" />
        <Profile path="profile" />
       

        <UnderConstruction path="underConstruction" />
        <Search path="search" />
        <HomePage path="/" />
     
      </Splash>
    </Router>
  );
}

export default App;
