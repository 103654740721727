import React from "react";
import rtlPlugin from "stylis-plugin-rtl";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector, shallowEqual } from "react-redux";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { ThemeProvider } from "@mui/material/styles";

import Theme from "./theme";

const cacheRtl = createCache({
  key: "css",
  stylisPlugins: [rtlPlugin],
});

const StyleLayoutProvider = function ({ children }) {
  const theme = useSelector((state) => state.theme, shallowEqual);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={Theme({ ...theme, direction: "rtl" })}>
        <CssBaseline />
        {children}
      </ThemeProvider>
      {/* </ThemeProvider> */}
    </CacheProvider>
  );
};

export default StyleLayoutProvider;
