import * as React from "react";
import BaseLayout from "~/Layout/Main.Layout";
import dic from "~/data/dic";
import { Box, Dialog, Typography, DialogContent, Button } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import { getWisdomList, getWisdom } from "~/modules/Wisdom/wisdomCrud";
import WisdomPlayer from "./player";
import TablePagination from "~/theme/components/TablePagination";
import Search from "~/theme/components/search";
import { search } from "~/modules/Search/searchCrud";
import persianJs from "persianjs";
import DeleteIcon from "@mui/icons-material/Close";
import WisdomList from "./list";
import SelectedPlaylist from "~/Pages/Playlist/selected.list";

const WisdomPage = ({ shareId }) => {
  const [{ list, totalCount }, setList] = React.useState({});

  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(12);
  const [newQuery, setNewQuery] = React.useState("");
  const [query, setQuery] = React.useState("");
  const [result, setResult] = React.useState(null);
  const [{ show, current }, setCurrent] = React.useState({
    show: false,
  });

  const [{ showPlaylist, currentItem }, setPlaylist] = React.useState({
    showPlaylist: false,
  });

  React.useEffect(() => {
    getWisdomList({ page, size }, (_e, res) => {
      setList(res?.data);
    });
  }, [setList, page, size]);

  const handleClickClip = React.useCallback(
    (show, current) => () => {
      setCurrent({ show, current });
    },
    [setCurrent]
  );

  React.useEffect(() => {
    if (shareId) {
      getWisdom({ id: shareId }, (_e, res) => {
        setCurrent({ show: true, current: res?.data });
      });
    }
  }, [shareId, setCurrent]);

  const handleChangePage = React.useCallback(
    (_event, newPage) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event) => {
      setSize(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setPage, setSize]
  );

  const handleKeyDown = React.useCallback(
    (event) => {
      if (event.keyCode === 13 && newQuery) {
        setQuery(newQuery);
      }
    },
    [newQuery, setQuery]
  );

  const handleChange = React.useCallback(
    (event) => setNewQuery(event.target.value),
    [setNewQuery]
  );

  React.useEffect(() => {
    if (query) {
      search({ query, doc: "wisdom" }, (_e, res) => {
        setResult(res?.data);
      });
    }
  }, [query, setResult]);

  const handlePlaylist = React.useCallback(
    (show, item) => (event) => {
      event?.stopPropagation();
      setPlaylist({ showPlaylist: show, currentItem: item });
    },
    [setPlaylist]
  );

  return (
    <>
      <SelectedPlaylist
        wisdomId={currentItem?.id}
        show={showPlaylist || false}
        onClose={handlePlaylist(false)}
      />
      <Dialog
        open={show}
        maxWidth="sm"
        fullWidth
        onClose={() => setCurrent(false)}
      >
        <DialogContent>
          <WisdomPlayer
            current={current}
            onClose={() => setCurrent(false)}
            onPlaylist={handlePlaylist}
          />
        </DialogContent>
      </Dialog>
      <BaseLayout pageName={`${dic.wisdom} `}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 2,
            px: { md: 6, sm: 0.5, xs: 0.5 },
            position: "sticky",
            top: { xs: 54, sm: 54, md: 80, lg: 80, xl: 80 },
            borderRadius: 5,
            bgcolor: "rgba(249,249,249, 0.8)",
            backdropFilter: "blur(10px)",
            "-webkit-backdrop-filter": "blur(10px)",
            zIndex: 999,
          }}
        >
          <Box
            sx={{
              width: 1 / 1,
              flex: 1,
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              alignItems: "center",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "space-around",
              },
            }}
          >
            <Box
              sx={{
                flex: 1,
                width: 1 / 1,
                px: 2,
                ml: { xs: 0, sm: 0, md: 3 },
              }}
            >
              <Search
                placeholder={dic.searchValue}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                value={newQuery}
              />
            </Box>
            <TablePagination
              count={totalCount || 0}
              component="div"
              rowsPerPage={size}
              page={page}
              sizes={[12, 30, 60, 120]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
            />
          </Box>
          {result && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                px: { xs: 2, sm: 2, md: 6 },
                pb: 1,
              }}
            >
              <Typography variant="caption">{dic.searchResult}:</Typography>
              <Typography sx={{ mx: 1 }} component={"p"} variant="body1">
                {persianJs(result?.total?.value || "0")
                  .englishNumber()
                  .toString()}
              </Typography>
              <Typography variant="caption">{dic.searchItems}</Typography>
              <Typography
                sx={{ mx: 1, color: "primary.main" }}
                component={"p"}
                variant="body1"
              >
                {query}
              </Typography>
              <Box sx={{ flex: 1 }} />
              <Button
                onClick={() => {
                  setResult(null);
                  setQuery("");
                  setNewQuery("");
                }}
                variant="contained"
              >
                <DeleteIcon />
              </Button>
            </Box>
          )}
        </Box>
        {result && (
          <WisdomList
            list={result?.list}
            isSearch
            onPlay={handleClickClip}
            onPlaylist={handlePlaylist}
          />
        )}
        {!result && (
          <WisdomList
            list={list}
            onPlay={handleClickClip}
            onPlaylist={handlePlaylist}
          />
        )}
      </BaseLayout>
    </>
  );
};

export default WisdomPage;
