import * as React from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Slider,
  IconButton,
} from "@mui/material";
import ReactPlayer from "react-player";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";

const theme = createTheme({
  direction: "lrt",
  palette: {
    mode: "light",
    primary: { light: "#86e0e6", main: "#40ba8d", dark: "#40ba8d" },
  },
});

const WisdomPlayer = ({ current, onPlaylist, onClose }) => {
  const [isPlaying, setIsPlaying] = React.useState(true);
  const [duration, setDuration] = React.useState(null);
  const [player, setPlayer] = React.useState(null);
  const [, setPlayerRef] = React.useState(null);
  const [value, setPosition] = React.useState(0);
  const [seek, setSeek] = React.useState(false);

  const matches = useMediaQuery("(max-width:480px)");

  const ref = (p) => {
    setPlayerRef(p);
  };

  const onReady = React.useCallback(
    (p) => {
      setPlayer(p);
    },
    [setPlayer]
  );

  const handleDuration = React.useCallback(
    (value) => {
      setDuration(value * 1000);
    },
    [setDuration]
  );

  const sliderChange = React.useCallback(
    (_e, value) => {
      setPosition(value);
      player.seekTo(value / 1000);
    },
    [setPosition, player]
  );

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (player && !seek) setPosition(player.getCurrentTime() * 1000);
    }, 100);
    return () => clearInterval(timer);
  }, [player, seek]);

  const onPlay = React.useCallback(
    () => setIsPlaying(!isPlaying),
    [setIsPlaying, isPlaying]
  );

  const handleSeekMouseDown = React.useCallback(() => setSeek(true), [setSeek]);
  const handleSeekMouseUp = React.useCallback(() => setSeek(false), [setSeek]);

  const handleShare = React.useCallback(async () => {
    await navigator.share({
      // title: dic.title,
      text: `${current?.title}\n`,
      url: `${window.location.href}/${current?.id}`,
    });
  }, [current]);

  return (
    <>
      {current && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minHeight: 150,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", flex: 1, pr: 2 }}
            >
              {current.title}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {!player && (
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                marginLeft: "-20px",
                marginTop: "-20px",
              }}
            />
          )}

          <Box
            ref={ref}
            sx={{
              display: "flex",
              alignItems: "center",
              minHeight: 100,

              overflow: "hidden",
              borderRadius: 2,
              "& >div": {
                width: "100% !important",
              },
            }}
          >
            <ReactPlayer
              playing={isPlaying}
              onReady={onReady}
              height="auto"
              onDuration={handleDuration}
              //controls={isFullscreen}
              url={current?.file}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row-reverse",
            }}
          >
            <a
              rel="noreferrer"
              target={"_blank"}
              download={"monib"}
              href={current?.file}
            >
              <IconButton>
                <Box
                  src={`/icons/download.svg`}
                  component={"img"}
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </IconButton>
            </a>
            {onPlaylist && (
              <IconButton onClick={onPlaylist(true, current)}>
                <Box
                  src={`/icons/myPlayList.svg`}
                  component={"img"}
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </IconButton>
            )}
            <IconButton onClick={handleShare}>
              <Box
                src={`/icons/share.svg`}
                component={"img"}
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </IconButton>
          </Box>

          <Box
            sx={{
              alignItems: "center",
              // width: 1 / 1,
              minHeight: 60,
              display: "flex",
              px: { xs: 0, sm: 0, md: 2 },
            }}
          >
            {!matches && (
              <Typography sx={{ px: 2 }}>
                {duration ? duration.toHHMMSS() : "00:00:00"}
              </Typography>
            )}

            <ThemeProvider theme={theme}>
              <Slider
                size="small"
                value={value}
                max={duration}
                onMouseDown={handleSeekMouseDown}
                onMouseUp={handleSeekMouseUp}
                onChange={sliderChange}
                sx={{
                  flex: 1,
                  "& .MuiSlider-thumb": {
                    background: "var(--main-color)",
                    transform: "translate(50%, -50%)",
                  },
                  "& .MuiSlider-track": {
                    background: "var(--main-color)",
                  },
                }}
              />
            </ThemeProvider>

            {!matches && (
              <Typography component={"div"} sx={{ px: 2 }}>
                {value ? value.toHHMMSS() : "00:00:00"}
              </Typography>
            )}

            <IconButton onClick={onPlay}>
              {isPlaying && (
                <Box
                  component={"img"}
                  src="/icons/pause.svg"
                  alt="pause button"
                  sx={{
                    width: 35,
                    height: 35,
                  }}
                />
              )}
              {!isPlaying && (
                <Box
                  component={"img"}
                  src="/icons/play.svg"
                  alt="play button"
                  sx={{
                    width: 35,
                    height: 35,
                  }}
                />
              )}
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
};

export default WisdomPlayer;
