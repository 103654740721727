import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const CustomizeInput = styled(InputBase, {
  shouldForwardProp: (props) => props !== "ltr" && props !== "error",
})(({ theme, ltr, error }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    fontWeight: "bold",
  },

  "& .MuiInputBase-input": {
    borderRadius: 50,
    ...(ltr && { direction: "rtl" }),
    position: "relative",
    //backgroundColor: theme.palette.mode === "light" ? "#fff" : "#2b2b2b",
    border: `2px solid var(--border-color)`,
    ...(error && {
      border: `2px solid ${theme.palette.secondary.main}`,
      boxShadow: `${alpha(theme.palette.secondary.main, 0.25)} 0 0 0 0.2rem`,
    }),
    fontSize: 16,
    padding: "8px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(
        error ? theme.palette.secondary.main : theme.palette.primary.main,
        0.25
      )} 0 0 0 0.2rem`,
      borderColor: error ? theme.palette.secondary : theme.palette.primary.main,
    },
  },
}));

const MonibTextField = ({
  label,
  mt,
  ltr,
  sx,
  fullWidth = true,
  required,
  ...props
}) => {
  return (
    <FormControl sx={{ mt, width: 1 / 1, alignItems: "center" }}>
      <InputLabel
        required={required}
        sx={{
          fontWeight: "bold",
          fontSize: { xs: 16, sm: 16, md: 20, lg: 20, xl: 20 },
          color: "text.primary",
          // whiteSpace: "pre-line",
          // position: "relative",
          // textAlign: "center",
          // lineHeight:2,
          mb: 0,
        }}
        shrink
      >
        {label}
      </InputLabel>
      <CustomizeInput
        ltr={ltr}
        fullWidth={fullWidth}
        {...props}
        sx={{ ...sx }}
      />
    </FormControl>
  );
};

export default MonibTextField;
