import React from "react";
import MuiTablePagination from "@mui/material/TablePagination";

import dic from "~/data/dic";


const TablePagination = ({ sizes=[10, 25, 50, 100, 200], ...props }) => {
  

  const labelDisplay = React.useCallback(
    ({ from, to, count }) =>
      count !== -1 ? ` ${from}-${to} ${dic.of} ${count}` : "",
    []
  );

  return (
    <MuiTablePagination
      labelDisplayedRows={labelDisplay}
      rowsPerPageOptions={sizes}
      labelRowsPerPage={dic.rowOnPage}
      {...props}
    />
  );
};

export default TablePagination;
