import { AppBar, Box, ButtonBase, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, navigate } from "@reach/router";
import dic from "~/data/dic";
import MonibVerticalDivider from "~/theme/components/vertical.divider";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import _ from "lodash";

const MonibAppBar = ({ pageName, links }) => {
  return (
    <AppBar
      sx={{
        borderRadius: (theme) => theme.spacing(0, 0, 2, 2),
        background: "url(/images/appbar-back.jpg)",
        color: "white",
      }}
      elevation={0}
      position="sticky"
    >
      <Box
        sx={{
          height: 82,
          display: "flex",
          position: "relative",
          px: 3,
          pt: 2,
          alignItems: "center",
          color: "white",
          '& a':{
            height:30
          }
        }}
      >
        <Link to="/">
          <Box
            component={"img"}
            sx={{ maxWidth: 60 }}
            src="/icons/monib-text.svg"
          />
        </Link>
        <Box sx={{ width: 3, height: 30, mx: 2, bgcolor: "primary.main" }} />
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{ color: "white" }}
          separator={<MonibVerticalDivider sx={{ height: 20, mx: 2 }} />}
        >
          <Link to="/">
            <ButtonBase sx={{ color: "white" }}>
              <Typography component={"div"} color="inherit">
                {dic.homePage}
              </Typography>
            </ButtonBase>
          </Link>
          {_.map(links, (item, index) => (
            <Link key={index} to={item.link}>
              <ButtonBase sx={{ color: "white" }}>
                <Typography component={"div"} color="inherit">
                  {item.title}
                </Typography>
              </ButtonBase>
            </Link>
          ))}
          {pageName && (
            <Typography
              sx={{ fontWeight: "bold", color: "primary.main" }}
              variant="h6"
              component={"div"}
            >
              {pageName}
            </Typography>
          )}
        </Breadcrumbs>
        <Box sx={{ flex: 1 }} />
        <IconButton onClick={() => navigate(-1)}>
          <BackIcon fontSize="large" color="primary" />
        </IconButton>
      </Box>
    </AppBar>
  );
};

export default MonibAppBar;
