import { Box, Typography } from "@mui/material";
import Frame from "~/theme/components/frame";
import MonibVerticalDivider from "~/theme/components/vertical.divider";

const Footer = () => {
  return (
    <Frame
      sx={{
        height: 58,
        width: 1 / 1,
        mt: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        px: 3,
      }}
      component={"footer"}
    >
      <Box
        component={"img"}
        src="/icons/footer-text.svg"
        sx={{ maxHeight: 20 }}
      />
      <MonibVerticalDivider sx={{ height: 26, mx: 2 }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: 150,
          opacity:0.5
        }}
      >
        <Box component={"img"} src="/icons/android.svg" sx={{ height: 24 }} />
        <Box component={"img"} src="/icons/apple.svg" sx={{ height: 24 }} />
        <Box component={"img"} src="/icons/windows.svg" sx={{ height: 24 }} />
      </Box>
      <MonibVerticalDivider sx={{ height: 26, mx: 2 }} />
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: 80,
        }}
      >
        <Box component={"img"} src="/icons/instagram.svg" sx={{ height: 24 }} />
        <Box component={"img"} src="/icons/telegram.svg" sx={{ height: 24 }} />
      </Box>
      <MonibVerticalDivider sx={{ height: 26, mx: 2 }} /> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Typography variant="subtitle2">Monib</Typography>
        <Typography variant="subtitle2">
          CopyRight © Bayan Hedayat Noor
        </Typography>
      </Box>
    </Frame>
  );
};

export default Footer;
