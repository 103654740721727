import { createSlice } from "@reduxjs/toolkit";

const initialLayoutState = {
  currentPage: { id: "home" },
  isLoading: false,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState: initialLayoutState,
  reducers: {
    changePage: (state, action) => {
      state.error = null;
      state.currentPage = action.payload;
    },
    setLoading: (state, action) => {
      state.error = null;
      state.isLoading = action.payload;
    },
  },
});
