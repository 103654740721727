import * as React from "react";
import { Box, ButtonBase, Typography, IconButton } from "@mui/material";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";

const WisdomList = ({ list, onPlay, isSearch, onPlaylist }) => {
  const matches = useMediaQuery("(max-width:480px)");

  const handleShare = React.useCallback(
    (current) => async (event) => {
      event.stopPropagation();
      await navigator.share({
        text: `${current?.title}\n`,
        url: `${window.location.href}/${current?.id}`,
      });
    },
    []
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
        justifyContent: "space-between",
        py: { xs: 1, sm: 1, md: 3 },
        px: { md: 4, sm: 2, xs: 2 },
      }}
    >
      {_.map(list, (item) => (
        <ButtonBase
          key={item.id}
          component="div"
          onClick={onPlay(true, item)}
          sx={{
            flexBasis: { xs: "100%", sm: "100%", md: "33%" },
            flexDirection: "row",
            mb: 3,
            minHeight: { xs: 60, sm: 60, md: 120 },
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              width: { xs: 90, sm: 90, md: 120 },
              borderRadius: 4,
              height: { xs: 85, sm: 85, md: 116 },
              objectFit: "cover",
              border: 2,
              borderColor: "#b6bbba",
            }}
            title={item.title}
            component={"img"}
            src={item.image}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              flex: 1,
              pl: 2,
              pr: { xs: 0, sm: 0, md: 2 },
            }}
          >
            {!isSearch && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  flex: 1,
                  fontSize: {
                    xs: "0.7rem",
                    sm: "0.7rem",
                    md: "0.8571428571428571rem",
                  },
                  textAlign: "left",
                }}
              >
                {item.title}
              </Typography>
            )}

            {isSearch && (
              <Typography
                variant="body1"
                component="div"
                sx={{
                  fontWeight: "bold",
                  "& em": {
                    color: "primary.main",
                  },
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: item?.highlight,
                  }}
                ></div>
              </Typography>
            )}

            <Box sx={{ display: "flex", mt: 1 }}>
              <Typography
                variant={matches ? "caption" : "body1"}
                sx={{ mr: 2 }}
              >
                {item.stringDuration}
              </Typography>
              <Typography
                variant={matches ? "caption" : "body1"}
                sx={{ mr: 2 }}
              >
                {item.stringSize}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 1,
                display: "flex",
                alignItems: "center",
                minWidth: 80,
                justifyContent: "space-between",
                flexDirection: "row-reverse",
              }}
            >
              <a
                rel="noreferrer"
                target={"_blank"}
                download={item?.file}
                href={item?.file}
              >
                <IconButton
                  onClick={(event) => event.stopPropagation()}
                  sx={{ ml: 0.5 }}
                  size="small"
                >
                  <Box
                    src={`/icons/download.svg`}
                    component={"img"}
                    sx={{
                      height: { xs: 13, sm: 13, md: 14 },
                    }}
                  />
                </IconButton>
              </a>
              <IconButton onClick={onPlaylist(true, item)} size="small">
                <Box
                  src={`/icons/myPlayList.svg`}
                  component={"img"}
                  sx={{
                    height: { xs: 13, sm: 13, md: 14 },
                  }}
                />
              </IconButton>
              <IconButton size="small" onClick={handleShare(item)}>
                <Box
                  src={`/icons/share.svg`}
                  component={"img"}
                  sx={{
                    height: { xs: 13, sm: 13, md: 14 },
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </ButtonBase>
      ))}
    </Box>
  );
};

export default WisdomList;
