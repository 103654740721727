import React, { useEffect } from "react";
import BaseLayout from "~/Layout/Main.Layout";
import dic from "~/data/dic";
import { Box, Divider, ButtonBase, Typography } from "@mui/material";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "~/theme/components/header";
import { fetchLast } from "~/modules/Last/lastAction";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@reach/router";

const LastSeenPage = () => {
  
  const matches = useMediaQuery("(max-width:480px)");
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      fetchLast()(dispatch);
    }
    fetchData();
  }, [dispatch]);

  const { list } = useSelector(({ last }) => last);

  return (
    <BaseLayout
      topFrame={<Header border center fullWidth title={dic.last} />}
      pageName={`${dic.last} `}
    >
      <Box
        sx={{
          minHeight: 100,
          px: {
            xl: 16,
            lg: 16,
            md: 16,
            sm: 1,
            xs: 1,
          },
          py: 6,
        }}
      >
        <Box>
          {_.map(list, (item) => (
            <React.Fragment key={item.id}>
              <ButtonBase
                onClick={() => navigate(`player/${item.id}`)}
                sx={{
                  width: 1 / 1,
                  height: {
                    xl: 55,
                    lg: 55,
                    md: 55,
                    sm: 40,
                    xs: 40,
                  },
                  px: {
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 1,
                    xs: 1,
                  },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {/* <Chip
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    minWidth: 100,
                    mx: 2,
                  }}
                  color="primary"
                  label={item.context}
                /> */}
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    ...(matches && {
                      fontSize: 14,
                    }),
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mx: 1,
                    ...(matches && {
                      fontSize: 14,
                    }),
                  }}
                >
                  {item.displayRow}
                </Typography>
                <Box sx={{ flex: 1 }} />
                <Typography variant="subtitle1">{item.displayDate}</Typography>
              </ButtonBase>
              <Divider sx={{ borderColor: "primary.main" }} />
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </BaseLayout>
  );
};

export default LastSeenPage;
