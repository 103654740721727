import * as React from "react";
import dic from "~/data/dic";
import { Box, Divider, Typography } from "@mui/material";
import PageDivider from "~/theme/components/page.divider";
import { getContentBySeriesIdAsync } from "~/modules/content/contentCrud";
import PlayerControl from "~/components/player.control";
import SubjectText from "~/components/subject.text";
import useMediaQuery from "@mui/material/useMediaQuery";
import BaseLayout from "~/Layout/Main.Layout";

const Header = React.memo(({ data, matches, series }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        minHeight: 80,
        borderRadius: 4,
        //bgcolor: "var(--main-color4)",
        bgcolor: "primary.main",
        borderColor: "text.primary",
        border: 2,
        mb: 1,
        px: 3,
      }}
    >
      <Typography
        color="black"
        sx={{ fontWeight: "bold", ...(matches && { fontSize: 14 }) }}
        variant="h5"
      >
        {(data || series)?.title}
      </Typography>
      <Box sx={{ flex: 1 }} />
      <Box>
        <Typography
          component={"div"}
          sx={{ ...(matches && { fontSize: 10 }) }}
          color="black"
          variant="body1"
        >
          {data?.description}
        </Typography>
        <Typography
          component={"div"}
          color="black"
          sx={{ mt: 0.5 }}
          variant="caption"
        >
          {data?.eventName}
        </Typography>
      </Box>
    </Box>
  );
});

Header.displayName = "PlayerHeader";

const ST = ({ type, setDefaultPosition, currentPosition, data, id, verse }) => {
  return (
    <SubjectText
      type={type}
      onTitleClick={(value) => setDefaultPosition(value.startPosition)}
      position={currentPosition}
      titles={data?.titles}
      lectureId={data?.lectureId}
      title={data?.title}
      verse={verse}
      seriesId={type === "quran" ? id : data?.seriesId}
    />
  );
};

const Pl = ({
  defaultPosition,
  setCurrentPosition,
  data,
  type,
  id,
  verse,
  isMobile,
}) => {
  return (
    <PlayerControl
      defaultPosition={defaultPosition}
      onChangePosition={(value) => setCurrentPosition(value)}
      lecture={data}
      type={type}
      verse={verse}
      isMobile={isMobile}
      id={id}
    />
  );
};

const QuranPlayerPage = ({ id, goto, verse }) => {
  
  const [series, setSeries] = React.useState(null);
  const [currentPosition, setCurrentPosition] = React.useState(null);
  const [defaultPosition, setDefaultPosition] = React.useState(0);
  const matches = useMediaQuery("(max-width:480px)");

  React.useEffect(() => {
    if (goto) {
      setDefaultPosition(+goto);
    }
  }, [setDefaultPosition, goto]);

  React.useEffect(() => {
    const fetch = async () => {
      const series = await getContentBySeriesIdAsync({ seriesId: id });
      setSeries(series?.data);
    };
    if (id) fetch();
  }, [id, setSeries]);

  return (
    <BaseLayout
      links={[
        {
          title: dic.quran,
          link: "/content/quran/",
        },
        {
          title: series?.title,
          link: `/series/${id}`,
        },
      ]}      
      topFrame={series && <Header matches={true} series={series} />}
    >
      {!matches && (
        <Box sx={{ display: "flex", minHeight: 100, p: 2 }}>
          <Box sx={{ flex: 1, px: 2 }}>
            {series && <Header series={series} />}
            <Box
              sx={{
                mt: 4,
              }}
            >
              <Pl
                defaultPosition={defaultPosition}
                setCurrentPosition={setCurrentPosition}                
                id={id}
                type={"quran"}
                verse={verse}
                isMobile={false}
              />
            </Box>
          </Box>
          <PageDivider />
          <Box sx={{ flex: 1 }}>            
            <Box
              sx={{
                mt: 4,
              }}
            >
              <ST
                setDefaultPosition={setDefaultPosition}
                currentPosition={currentPosition}                
                id={id}
                type={"quran"}
                verse={verse}
              />
            </Box>
          </Box>
        </Box>
      )}
      {matches && (
        <Box>
          <Box
            sx={{
              mt: 4,
            }}
          >
            <Box sx={{ px: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 } }}>
              {id && (
                <Pl
                  defaultPosition={defaultPosition}
                  setCurrentPosition={setCurrentPosition}
                  type={"quran"}                 
                  verse={verse}
                  id={id}
                  isMobile={matches}
                />
              )}
            </Box>
            <Divider sx={{ my: 2, mx: 2 }} />

            <ST
              setDefaultPosition={setDefaultPosition}
              currentPosition={currentPosition}              
              id={id}
              type={"quran"}
              verse={verse}
            />
          </Box>
        </Box>
      )}
    </BaseLayout>
  );
};

export default QuranPlayerPage;
