import React, { useEffect } from "react";
import BaseLayout from "~/Layout/Main.Layout";
import dic from "~/data/dic";
import { Box, Divider, Typography } from "@mui/material";
import _ from "lodash";
import PageDivider from "~/theme/components/page.divider";
import Header from "~/theme/components/header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fetchLive } from "~/modules/Live/liveAction";
import { useDispatch, useSelector } from "react-redux";
import Iframe from "react-iframe";
import { navigate } from "@reach/router";

const List = ({ live }) => {
  const matches = useMediaQuery("(max-width:480px)");
  return (
    <>
      <Box
        sx={{
          flex: 1,
          px: 1,

          display: "flex",
          flexDirection: "column",
          my: 1,
          alignItems: "center",
          width: 1 / 1,
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", mt: 5 }}
          variant={matches ? "h6" : "h5"}
        >
          {dic.schedule}
        </Typography>
        <Divider
          sx={{
            my: 2,
            borderColor: "text.primary",
            border: 1,
            minWidth: "90%",
          }}
        />
        {_.map(live?.list, (item, index) => (
          <React.Fragment key={index}>
            <Box
              sx={{
                height: 36,
                justifyContent: "space-around",
                px: { xs: 0, sm: 2, md: 2, lg: 2, xl: 2 },
                display: "flex",
                alignItems: "center",
                width: 1 / 1,
              }}
            >
              <Typography
                variant={matches ? "caption" : "body"}
                sx={{ fontWeight: "bold", mr: 1 }}
              >
                {item.title}
              </Typography>
              <Typography variant="caption">{item.displayDate}</Typography>
              {!matches && (
                <Typography variant="caption">
                  {dic.hour} {item.time}
                </Typography>
              )}
            </Box>
            <Divider sx={{ borderColor: "primary.main", minWidth: 1 / 1 }} />
          </React.Fragment>
        ))}
      </Box>

      <Typography
        sx={{ fontWeight: "bold", mt: 5 }}
        variant={matches ? "h6" : "h5"}
      >
        {dic.archiveSchedule}
      </Typography>
      <Divider
        sx={{ my: 2, borderColor: "text.primary", border: 1, minWidth: "90%" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          my: 1,
          alignItems: "stretch",
          width: 1 / 1,
        }}
      >
        {_.map(live?.archive, (item, index) => (
          <React.Fragment key={index}>
            <Box
              onClick={() =>
                item?.lectureId && navigate(`player/${item.lectureId}`)
              }
              sx={{
                display: "flex",
                alignItems: "center",
                height: 36,
                justifyContent: "space-between",
                px: 1,
                width: 1 / 1,
                ...(item?.lectureId && { cursor: "pointer" }),
                ...(!item?.lectureId && { opacity: 0.7 }),
              }}
            >
              <Typography
                variant={matches ? "caption" : "body1"}
                sx={{ fontWeight: "bold", mr: 1 }}
              >
                {item.title}
              </Typography>
              <Typography variant="caption">{item.displayDate}</Typography>
              {!matches && (
                <>
                  <Box sx={{ flex: 1 }} />
                  <Typography variant="caption">{item.time}</Typography>
                </>
              )}
            </Box>
            <Divider sx={{ borderColor: "primary.main" }} />
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

const LivePage = () => {
  const matches = useMediaQuery("(max-width:480px)");

  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      fetchLive()(dispatch);
    }
    fetchData();
  }, [dispatch]);

  const live = useSelector(({ live }) => live);

  return (
    <BaseLayout
      topFrame={
        <Header fullWidth border>
          <Box sx={{ display: "flex", width: 1 / 1, alignItems: "center" }}>
            <Typography sx={{ color: "white" }}>{dic.live}</Typography>
          </Box>
        </Header>
      }
      links={[]}
      pageName={`${dic.live} `}
    >
      <Box
        sx={{
          display: "flex",
          minHeight: live?.active ? 100 : 0,
          p: {
            xl: 2,
            lg: 2,
            md: 2,
            sm: 0,
            xs: 0,
          },
          pb: 6,
        }}
      >
        {!matches && (
          <>
            <Box sx={{ flex: 1, textAlign: "center" }}>
              <List live={live} />
            </Box>

            <PageDivider />
          </>
        )}

        {live?.active ? (
          <Box sx={{ flex: 1, px: 2, mt: 5 }}>
            <Box
              sx={{
                display: "flex",
                mb: 4,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  ...(matches && {
                    fontSize: 14,
                  }),
                }}
                variant="h5"
              >
                {live?.active?.title}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", mt: 5 }}>
              <Header title={dic.video} icon="/icons/video-light.svg" />
              <Box sx={{ flex: 1 }} />
              {!matches && (
                <>
                  {/* <Typography sx={{ mr: 1 }} variant="caption">
                    {dic.remainTime}:
                  </Typography> */}
                  <Box
                    sx={{
                      bgcolor: "primary.main",
                      color: "white",
                      fontSize: 12,
                      borderRadius: 4,
                      px: 1,
                      py: 0.5,
                    }}
                  >
                    {"در حال پخش"}
                  </Box>
                </>
              )}
            </Box>

            <Box sx={{ mt: 1 }}>
              <Iframe
                url={live?.active?.videoLink}
                width="100%"
                height={matches ? "auto" : "320px"}
                styles={{ minHeight: 200 }}
                id=""
                className=""
                display="block"
                position="relative"
              />
            </Box>

            <Box sx={{ my: 6 }} />
            <Box sx={{ pb: 8 }}>
              <Header title={dic.sound} icon="/icons/sound-light.svg" />
              <Box
                sx={{
                  mt: 2,
                  alignItems: "center",
                  width: 1 / 1,
                  justifyContent: "center",
                  borderRadius: 4,
                  borderColor: "text.primary",
                  border: 2,
                  minHeight: 50,
                  display: "flex",
                  px: 2,
                }}
              >
                {live?.active?.soundLink && (
                  <audio controls>
                    <source src={live?.active?.soundLink} type="audio/mpeg" />
                    Your browser does not support the audio tag.
                  </audio>
                )}
                {/* <IconButton disabled size="large">
                  <Box
                    sx={{ width: 45 }}
                    src={`/icons/play.svg`}
                    component={"img"}
                  />
                </IconButton> */}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={{ flex: matches ? 0 : 1 }} />
        )}
      </Box>
      {matches && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            px: 1,
          }}
        >
          <List live={live} />
        </Box>
      )}
    </BaseLayout>
  );
};

export default LivePage;
