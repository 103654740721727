import { Divider } from "@mui/material";

const MonibVerticalDivider = ({ sx }) => {
  return (
    <Divider
      component="div"
      sx={{ border: 1, borderColor: "primary.main", ...sx }}
      orientation="vertical"
    />
  );
};

export default MonibVerticalDivider;
