import React, { memo } from "react";
import _ from "lodash";
import {
  Box,
  ButtonBase,
  Divider,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { navigate } from "@reach/router";
import dic from "~/data/dic";
import useMediaQuery from "@mui/material/useMediaQuery";
import SelectedPlaylist from "~/Pages/Playlist/selected.list";
import ShareIcon from "~/assets/icons/share.svg";
import PlaylistIcon from "~/assets/icons/myPlayList.svg";

const SearchList = ({ result, onFast, value }) => {
  const matches = useMediaQuery("(max-width:480px)");
  const [{ show, topicId }, setShowPlaylist] = React.useState({ show: false });

  const handlePlaylist = React.useCallback(
    ({ show, topicId }) =>
      (event) => {
        event?.stopPropagation();
        setShowPlaylist({ show, topicId });
      },
    [setShowPlaylist]
  );
  

  return (
    <>
      <SelectedPlaylist
        topicId={topicId}
        show={show || false}
        onClose={handlePlaylist({ show: false })}
      />
      <Box sx={{ mt: 2 }}>
        {_.map(
          _.filter(result?.list, (v) => value === 0 || v.mainId === value),
          (value, index) => {
            return (
              <React.Fragment key={index}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: {
                      xs: 60,
                      sm: 40,
                      md: 40,
                      lg: 40,
                      xl: 40,
                    },
                    px: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: 1 / 1,
                    }}
                  >
                    <ButtonBase
                      component="div"
                      onClick={() =>
                        navigate(
                          `/player/${value.lectureId}/?goto=${value.startTime}`
                        )
                      }
                      sx={{
                        cursor: "pointer",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant={matches ? "caption" : "body1"}
                        sx={{
                          fontWeight: "bold",
                          "& em": {
                            color: "primary.main",
                          },
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: value?.highlight,
                          }}
                        ></div>
                      </Typography>
                      {matches && (
                        <Typography
                          sx={{ mx: 1 }}
                          component={"span"}
                          variant="caption"
                        >
                          {value?.contextName}،{value?.seriesDescription}{" "}
                          {value?.lectureRowNumber}
                        </Typography>
                      )}
                    </ButtonBase>
                    <Box sx={{ flex: 1 }} />
                    {!matches && (
                      <Typography
                        sx={{ mx: 1 }}
                        component={"span"}
                        variant="caption"
                      >
                        {value?.contextName}،{value?.seriesDescription}{" "}
                        {value?.lectureRowNumber}
                      </Typography>
                    )}
                    <IconButton
                      onClick={handlePlaylist({
                        show: true,
                        topicId: value.topicId,
                      })}
                    >
                      <Box
                        sx={{ width: 1 / 1, maxWidth: 15 }}
                        src={PlaylistIcon}
                        component={"img"}
                      />
                    </IconButton>
                    <IconButton sx={{ opacity: 0.5 }} disabled>
                      <Box
                        sx={{ maxWidth: 15 }}
                        src={ShareIcon}
                        component={"img"}
                      />
                    </IconButton>
                    <Button
                      onClick={onFast(value)}
                      //disabled
                      size="small"
                      variant="contained"
                    >
                      {dic.fastPlayer}
                    </Button>
                  </Box>
                </Box>
                <Divider sx={{ borderColor: "primary.main" }} />
              </React.Fragment>
            );
          }
        )}
      </Box>
    </>
  );
};

export default memo(SearchList);
