import { Box, ButtonBase, styled, Typography } from "@mui/material";
import { Link } from "@reach/router";
import QBack from "~/assets/images/b_quran.png";
import NBack from "~/assets/images/b_nhj.png";
import SBack from "~/assets/images/b_sahifa.png";
import SpBack from "~/assets/images/b_sp.png";
import dic from "~/data/dic";

const Key = styled(ButtonBase, {
  shouldForwardProp: (props) => props !== "small" && props !== "backImg",
})(({ theme, small, backImg }) => ({
  height: 95,
  ...(small && {
    "&>img": {
      height: 46,
    },
  }),
  ...(!small && {
    "&>img": {
      height: 52,
    },
  }),
  border: "3px solid var(--border-color)",
  position: "relative",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  marginBottom: 2,
  paddingLeft: 46,
  borderRadius: 4 * 4,
  background: `url(${backImg}) center center no-repeat`,
  backgroundSize: `400px 84px`,
  [theme.breakpoints.down("md")]: {
    height: 74,
    justifyContent: "center",
    paddingLeft: 0,
    marginBottom: 1 * 4,
    border: "2px solid var(--border-color)",
    "&>img": {
      height: 40,
    },
  },
}));

const CountLecture = ({count}) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        color: "text.primary",
        position: "absolute",
        right: {
          sm:20,
          md:40,
          lg:40,
          xl:40,
          xs:20
        },
        top: {
          sm:30,
          md:30,
          lg:30,
          xl:30,
          xs:0
        },
        fontWeight: "bold",
        fontSize:{
          sm:12,
          md:12,
          lg:12,
          xl:12,
          xs:10
        }
      }}
    >
      {(count).toPersianNumber()} {dic.lectureCount}
    </Typography>
  );
};

const MainButtons = ({count={},isMobile}) => {
  return (
    <Box
      sx={{
        display: "flex",
        mt: isMobile ? 0.5 : 1.5,
        flexDirection: "column",
        flex: 1,
        justifyContent: "space-between",
      }}
    >
      <Link to={"/content/quran"}>
        <Key backImg={QBack}>
          <img alt="quran" src={"/icons/quran.svg"} />
          <CountLecture count={count["1"]?.count || 0} />
        </Key>
      </Link>
      <Link to={"/content/nahj-al-balagha"}>
        <Key backImg={NBack} small>
          <img alt="nhj" src={"/icons/nhj.svg"} />
          <CountLecture count={count["3"]?.count || 0}  />
        </Key>
      </Link>
      <Link to={"/content/sahifa"}>
        <Key backImg={SBack}>
          <img alt="sahifa" src={"/icons/sahifa.svg"} />
          <CountLecture count={count["4"]?.count || 0}  />
        </Key>
      </Link>
      <Link to={"/content/sp"}>
        <Key backImg={SpBack}>
          <img alt="special" src={"/icons/sp.svg"} />
          <CountLecture count={count["5"]?.count || 0}  />
        </Key>
      </Link>
    </Box>
  );
};

export default MainButtons;
