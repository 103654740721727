import React from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import dic from "~/data/dic";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  deletePlaylist,
  removeLectureFromPlaylist,
  removeTopicFromPlaylist,
  removeWisdomFromPlaylist
} from "~/modules/PlayList/playListCrud";
import { fetchPlayList } from "~/modules/PlayList/playListAction";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const DeleteList = ({
  show = false,
  id,
  onClose,
  item,
  currentList,
  isTopic,
  isLecture,
  isWisdom
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();

  const submit = React.useCallback(() => {
    setIsLoading(true);
    if (item) {
      if (isTopic) {
        removeTopicFromPlaylist(
          { playlistId: currentList.id, topicId: item.topicId },
          () => {
            setIsLoading(false);
            onClose();
          }
        );
      }

      if (isLecture) {
        removeLectureFromPlaylist(
          { playlistId: currentList.id, lectureId: item.lectureId },
          () => {
            setIsLoading(false);
            onClose();
          }
        );
      }

      if (isWisdom) {
        removeWisdomFromPlaylist(
          { playlistId: currentList.id, wisdomId: item.id },
          () => {
            setIsLoading(false);
            onClose();
          }
        );
      }

    } else {
      deletePlaylist({ id }, () => {
        dispatch(fetchPlayList);
        setIsLoading(false);
        onClose();
      });
    }
  }, [
    id,
    setIsLoading,
    onClose,
    dispatch,
    currentList,
    isLecture,
    isTopic,   
    item,
    isWisdom
  ]);

  useEffect(() => setIsLoading(false), [show, setIsLoading]);

  return (
    <Dialog open={show}>
      <DialogContent>{dic.deletedPlaylist}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{dic.cancel}</Button>
        <LoadingButton
          loading={isLoading}
          onClick={submit}
          color="secondary"
          variant="contained"
        >
          {dic.ok}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteList;
