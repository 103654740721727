import { createSlice } from "@reduxjs/toolkit";

const initialLiveState = {
  active: null,
  list: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const liveSlice = createSlice({
  name: "live",
  initialState: initialLiveState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    liveFetched: (state, action) => {
      const { list, active, archive } = action.payload;

      state.error = null;
      state.list = list;
      state.active = active;
      state.archive = archive;
    },
  },
});
