import {
  Box,
  ButtonBase,
  alpha,
  Typography,
  Link as MLink,
} from "@mui/material";
import { Link } from "@reach/router";
import dic from "~/data/dic";
import { useSelector } from "react-redux";
import UserIcon from "~/assets/icons/user.svg";
import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MSwipeableDrawer from "@mui/material/SwipeableDrawer";
import { styled } from "@mui/material/styles";
import ListItemAvatar from "@mui/material/ListItemAvatar";


const SwipeableDrawer = styled(MSwipeableDrawer)(({ theme }) => ({
  ".MuiDrawer-paper": {
    background: "rgba(45, 61, 53,0.8)",
    backdropFilter: "blur(8px)",
    borderRadius: theme.spacing(0, 4, 4, 0),
    height:440,
    display:"flex",
    alignItems:"center",
    top:'50%',
    marginTop:-220
  },
}));

const Key = styled(ButtonBase, {
  shouldForwardProp: (props) => props !== "isTopMargin",
})(({ isTopMargin, theme }) => ({
  width: 44,
  height: 44,
  borderRadius: 4 * 4,
  ...(isTopMargin && { marginTop: 8 }),
  border: "3px solid var(--border-color)",
  color: "var(--primary-color)",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& img": {
    width: 26,
    height: 26,
  },
  "&:hover": {
    boxShadow: `${alpha(theme.palette.primary.main, 0.3)} 0 0 0 0.2rem`,
    //borderColor: theme.palette.primary.main,
  },
}));

const MainMenu = ({ isHome }) => {
  const { userData } = useSelector(({ auth }) => auth);
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => () => {
    setState(open);
  };

  return (
    <>
      <SwipeableDrawer
        anchor={"left"}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{}}
      >
        <Box
          sx={{
            p: 2,
            py: 4,
            a: {
              textDecoration: "none",
              color: "white",
              fontWeight: "bold",
            },
          }}
        >
          <List sx={{ width: "100%", maxWidth: 360 }}>
            <MLink href="https://hedayatnoor.org/main/prof" target="_blank">
              <ListItem>
                <ListItemAvatar>
                  <Box
                    component={"img"}
                    sx={{ width: 30, height: 30 }}
                    src="/icons/prof.svg"
                  ></Box>
                </ListItemAvatar>
                <ListItemText primary="محمدعلی انصاری" secondary="" />
              </ListItem>
            </MLink>

            <MLink href="https://shop.hedayatnoor.com/" target="_blank">
              <ListItem>
                <ListItemAvatar>
                  <Box
                    component={"img"}
                    sx={{ width: 30, height: 30 }}
                    src="/icons/book.svg"
                  ></Box>
                </ListItemAvatar>
                <ListItemText primary="فروشگاه کتاب" secondary="" />
              </ListItem>
            </MLink>
            <MLink href="https://t.me/MohammadAliAnsari" target="_blank">
              <ListItem>
                <ListItemAvatar>
                  <Box
                    component={"img"}
                    sx={{ width: 30, height: 30 }}
                    src="/icons/teleg.svg"
                  ></Box>
                </ListItemAvatar>
                <ListItemText primary="کانال تلگرام" secondary="" />
              </ListItem>
            </MLink>

            <MLink href="https://eitaa.com/hedayatnoor" target="_blank">
              <ListItem>
                <ListItemAvatar>
                  <Box
                    component={"img"}
                    sx={{ width: 30, height: 30 }}
                    src="/icons/eata.svg"
                  ></Box>
                </ListItemAvatar>
                <ListItemText primary="کانال ایتا" secondary="" />
              </ListItem>
            </MLink>
            <MLink
              href="https://www.instagram.com/hedayatnoor/?hl=fa"
              target="_blank"
            >
              <ListItem>
                <ListItemAvatar>
                  <Box
                    component={"img"}
                    sx={{ width: 30, height: 30 }}
                    src="/icons/insta.svg"
                  ></Box>
                </ListItemAvatar>
                <ListItemText primary="صفحه اینستاگرام" secondary="" />
              </ListItem>
            </MLink>
            <MLink href="https://hedayatnoor.com/" target="_blank">
              <ListItem>
                <ListItemAvatar>
                  <Box
                    component={"img"}
                    sx={{ width: 30, height: 30 }}
                    src="/icons/site.svg"
                  ></Box>
                </ListItemAvatar>
                <ListItemText
                  primary="وبسایت موسسه بیان هدایت نور"
                  secondary=""
                />
              </ListItem>
            </MLink>
          </List>
        </Box>
      </SwipeableDrawer>
      <Box
        sx={{
          position: "fixed",
          width: "inherit",
          display: "flex",
          ml: -10,
          flexDirection: "column",
        }}
      >
        {/* <Key>
        <img alt="menu" src="/icons/menu.svg" />
      </Key> */}
        {isHome && (
          <Link to="/">
            <Key isTopMargin>
              <img alt="home" src="/icons/home.svg" />
            </Key>
          </Link>
        )}
        <Link to="/profile">
          <Key isTopMargin>
            {!userData?.userId && (
              <Typography
                sx={{ fontWeight: "bold", mb: 0.5 }}
                component={"div"}
              >
                {dic.login}
              </Typography>
            )}
            {userData?.userId && <Box src={UserIcon} component={"img"} />}
          </Key>
        </Link>

        <ButtonBase
          onClick={toggleDrawer(true)}
          sx={{
            width: 44,
            height: 44,
            mt: 1,
            borderRadius: 4,
            border: "3px solid var(--border-color)",
            color: "var(--primary-color)",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& img": {
              width: 26,
              height: 26,
            },
            "&:hover": {
              boxShadow: (theme) =>
                `${alpha(theme.palette.primary.main, 0.3)} 0 0 0 0.2rem`,
              //borderColor: theme.palette.primary.main,
            },
          }}
        >
          <Box component={"img"} src="/icons/menu.svg" />
        </ButtonBase>

        {/* <Key isTopMargin>
        <img alt="setting" src="/icons/setting.svg" />
      </Key> */}
      </Box>
    </>
  );
};

export default MainMenu;
