import { createSlice } from "@reduxjs/toolkit";


const initialCallingCodeState = {
  current: null,
  list: null,
};

export const initialAuthState = {
  accessToken: "",
  userData: {},
  id: 0,
};

export const callTypes = {
  list: "list",
  action: "action",
};



export const callingCodesSlice = createSlice({
  name: "callingCodes",
  initialState: initialCallingCodeState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    callingCodesFetched: (state, action) => {
      const { list, current } = action.payload;    
      state.error = null;
      state.list = list;
      state.current = current;
    },
  },
});

export const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    authFetched: (state, action) => {
      const { accessToken, userData } = action.payload;      
      state.accessToken = accessToken;
      state.userData = userData;      
    
    },
  },
});
