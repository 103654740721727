import React, { useCallback, useState } from "react";
import BaseLayout from "~/Layout/Main.Layout";
import dic from "~/data/dic";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import { alpha } from "@mui/material/styles";
import SelectedIcon from "@mui/icons-material/ArrowBackIos";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileList from "./mobile.list";
import PlayListItem from "./items";
import { fetchPlayList } from "~/modules/PlayList/playListAction";
import { useDispatch, useSelector } from "react-redux";
import ManagePlaylist from "./manage";
import EditIcon from "@mui/icons-material/Edit";
import DeleteList from "./delete.list";
import FastPlayer from "~/components/fast.player";
import WisdomPlayer from "~/Pages/wisdom/player";

const PlaylistPage = () => {
  const [current, setCurrent] = React.useState(null);
  const [{ show, playlist }, setPlaylist] = React.useState({ show: false });
  const [
    { showDeleteList, deletedPlaylistId, item, isLecture, isTopic, isWisdom },
    setDeletedPlaylist,
  ] = React.useState({ showDeleteList: false });

  const [{ showFasPlayer, lectureId, startTime, text, endTime }, setPlayer] =
    useState({
      showFasPlayer: false,
      lectureId: null,
    });

  const [{ showWisdomPlayer, wisdom }, setWisdomPlayer] = useState({
    showWisdomPlayer: false,
    wisdom: null,
  });

  const matches = useMediaQuery("(max-width:480px)");

  const dispatch = useDispatch();

  React.useEffect(() => {
    async function fetchData() {
      dispatch(fetchPlayList);
    }
    fetchData();
  }, [dispatch]);

  const {
    list: { list },
  } = useSelector((state) => state.playlist);

  const handleSelected = React.useCallback(
    (newValue) => () => {
      setCurrent(newValue);
    },
    [setCurrent]
  );

  const handleManage = React.useCallback(
    ({ show, playlist }) =>
      (event) => {
        event?.stopPropagation();
        setPlaylist({ show, playlist });
      },
    [setPlaylist]
  );

  const handleDeleted = React.useCallback(
    ({ show, id, item, isTopic, isLecture, isWisdom }) =>
      (event) => {
        if (!show && current?.id) {
          dispatch(fetchPlayList);
        }

        event?.stopPropagation();
        setDeletedPlaylist({
          showDeleteList: show,
          deletedPlaylistId: id,
          item,
          isTopic,
          isLecture,
          isWisdom,
        });
      },
    [setDeletedPlaylist, current, dispatch]
  );

  const handleFastPlayer = useCallback(
    ({ lectureId, startTime, text, endTime }) =>
      (event) => {
        event?.stopPropagation();
        setPlayer((prv) => ({
          showFasPlayer: !prv.showFasPlayer,
          lectureId,
          startTime,
          endTime,
          text,
        }));
      },
    [setPlayer]
  );

  const handleWisdomPlayer = useCallback(
    (wisdom) => (event) => {
      event?.stopPropagation();
      setWisdomPlayer((prv) => ({
        showWisdomPlayer: !prv.showWisdomPlayer,
        wisdom,
      }));
    },
    [setWisdomPlayer]
  );

  return (
    <>
      <Dialog
        open={showWisdomPlayer}
        maxWidth="sm"
        fullWidth
        onClose={handleWisdomPlayer({})}
      >
        <DialogContent>
          <WisdomPlayer current={wisdom} onClose={handleWisdomPlayer({})} />
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={showFasPlayer}
        onClose={handleFastPlayer({})}
      >
        <DialogContent>
          <FastPlayer
            defaultPosition={startTime}
            lectureId={lectureId}
            text={text}
            endTime={endTime}
          />
        </DialogContent>
      </Dialog>
      <ManagePlaylist
        show={show}
        playlist={playlist}
        onClose={handleManage({ show: false })}
      />
      <DeleteList
        item={item}
        show={showDeleteList}
        isLecture={isLecture}
        isTopic={isTopic}
        currentList={current}
        isWisdom={isWisdom}
        onClose={handleDeleted({ show: false })}
        id={deletedPlaylistId}
      />
      <BaseLayout pageName={`${dic.myPlayList}`}>
        {!matches && (
          <Box sx={{ display: "flex", minHeight: 100, py: 6, px: 4 }}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                onClick={handleManage({ show: true })}
                variant="contained"
                endIcon={<AddIcon />}
              >
                {dic.addPlaylist}
              </Button>
              <Box sx={{ mt: 2, width: 1 / 1 }}>
                {_.map(list, (item) => (
                  <Box
                    key={item.id}
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  >
                    <Box
                      onClick={handleSelected(item)}
                      sx={{
                        width: 1 / 1,
                        flex: 1,
                        borderRadius: 4,
                        height: 50,
                        display: "flex",
                        cursor: "pointer",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        px: 2,
                        border: "2px solid var(--border-color)",
                        ...(current?.id === item.id && {
                          borderColor: "primary.main",
                          color: "primary.main",
                        }),
                        "&:hover": {
                          boxShadow: (theme) =>
                            `${alpha(
                              theme.palette.primary.main,
                              0.3
                            )} 0 0 0 0.2rem`,
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                        }}
                        variant="h6"
                      >
                        {item.title}
                      </Typography>
                      <Typography sx={{ mx: 1 }} variant="subtitle1">
                        {dic.createdPlayList}
                        {item.createDate}
                      </Typography>
                      <Box sx={{ flex: 1 }} />
                      <IconButton disabled sx={{ opacity: 0.5 }}>
                        <Box
                          component={"img"}
                          sx={{ width: 22 }}
                          src="/icons/share.svg"
                        />
                      </IconButton>
                      <IconButton
                        onClick={handleDeleted({ show: true, id: item.id })}
                      >
                        <Box
                          component={"img"}
                          sx={{ width: 22 }}
                          src="/icons/delete.svg"
                        />
                      </IconButton>
                      <IconButton
                        onClick={handleManage({ show: true, playlist: item })}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        flex: "0 0 40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {current?.id === item.id && (
                        <SelectedIcon color="primary" />
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1,
                pt: 6,
                pb: 2,
                alignItems: "stretch",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  borderRadius: 4,
                  flex: 1,
                  height: 1 / 1,
                  justifyContent: "flex-start",
                  px: 3,
                  py: 1,
                  border: 2,
                  borderColor: "primary.main",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "primary.main",
                  }}
                  variant="h6"
                >
                  {current?.title}
                </Typography>
                <Divider sx={{ my: 1 }} />
                {_.map(current?.content?.lectures, (item, index) => (
                  <PlayListItem
                    key={`${current.id}-${item.lectureId}-${index}`}
                    onDelete={handleDeleted}
                    onFast={handleFastPlayer}
                    item={item}
                    isLecture
                  />
                ))}
                {_.map(current?.content?.topics, (item, index) => (
                  <PlayListItem
                    key={`${current.id}-${item.topicId}-${index}`}
                    onDelete={handleDeleted}
                    item={item}
                    onFast={handleFastPlayer}
                    isTopic
                  />
                ))}
                {_.map(current?.content?.wisdom, (item, index) => (
                  <PlayListItem
                    key={`${current.id}-${item.wisdomId}-${index}`}
                    onDelete={handleDeleted}
                    item={item}
                    onFast={handleWisdomPlayer}
                    isWisdom
                  />
                ))}
              </Box>
            </Box>
          </Box>
        )}
        {matches && (
          <MobileList
            onDelete={handleDeleted}
            onFast={handleFastPlayer}
            onAdd={handleManage}
            myList={list}
            onEdit={handleManage}
            onWisdomPlayer={handleWisdomPlayer}
          />
        )}
      </BaseLayout>
    </>
  );
};

export default PlaylistPage;
