import { createSlice } from "@reduxjs/toolkit";

const initialLiveState = {
  page: 0,
  size: 10,
  list: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const lastSlice = createSlice({
  name: "last",
  initialState: initialLiveState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    lastFetched: (state, action) => {
      const { list, page, size } = action.payload;

      state.error = null;
      state.list = list;
      state.page = page;
      state.size = size;
    },
  },
});
