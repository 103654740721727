import * as requestFromServer from "./liveCrud";
import { liveSlice, callTypes } from "./liveSlice";

const { actions } = liveSlice;

export const fetchLive = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLive()
    .then((response) => {
      const list = response.data;
      dispatch(actions.liveFetched(list));
    })
    .catch((error) => {
      error.clientMessage = "Can't get live data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
