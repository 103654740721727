import * as React from "react";
import BaseLayout from "~/Layout/Main.Layout";
import dic from "~/data/dic";
import { Box, Divider, Typography } from "@mui/material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

const SchedulePage = () => {

  const [uk, setUk] = React.useState(false);

  React.useEffect(() => {
    const uk = window.location.href?.indexOf("monibapp.co.uk") > -1;
    setUk(uk);
  }, []);

  return (
    <BaseLayout pageName={`${dic.support}`}>
      <Box
        sx={{
          display: "flex",
          minHeight: 200,
          p: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
      <Box>
        <Typography
          variant="h6"
          component={"div"}
          sx={{ direction: "rtl", fontWeight: "bold" }}
        >
          {uk ? "+44 7831 027503":"+98-915 523 31 21"}
        </Typography>
        {uk && <Typography
          variant="h6"
          component={"div"}
          sx={{ direction: "rtl", fontWeight: "bold" }}
        >
          info@monibapp.co.uk
        </Typography>}
        </Box>
        <Divider
          orientation="vertical"
          sx={{
            minHeight: 30,
            mx: 2,
            border: 1,
            borderColor: "var(--primary-color)",
          }}
        />
        <WhatsAppIcon fontSize="large"/>
        <PhoneInTalkIcon sx={{mx:2}} fontSize="large" />
      </Box>
    </BaseLayout>
  );
};

export default SchedulePage;
