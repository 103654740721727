import * as React from "react";
import BaseLayout from "~/Layout/Main.Layout";
import dic from "~/data/dic";
import { Box, Typography, Divider, Collapse } from "@mui/material";
import Quran from "~/components/quran";
import { getContentBySeriesIdAsync } from "~/modules/content/contentCrud";
import LectureList from "~/components/lecture.list";
import PageDivider from "~/theme/components/page.divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CustomToggleButton } from "~/theme/components/custom.toggle";
import { title, link } from "~/data/public.data";
import { Link } from "@reach/router";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled, alpha } from "@mui/material/styles";

export const CustomBox = styled(Box, {
  shouldForwardProp: (props) => !props.isSelected,
})(({ theme, isSelected }) => ({
  borderRadius: `${theme.spacing(3)} !important`,
  minWidth: 150,
  color: "black",
  padding: theme.spacing(0.5, 0),
  display: "flex",
  justifyContent: "center",
  background: "var(--main-color3)",
  cursor: "pointer",
  transition: theme.transitions.create([
    "border-color",
    "background-color",
    "box-shadow",
  ]),
  ...(isSelected && { color: "white", background: "var(--main-color)" }),
  "&:hover": {
    color: "white",
    background: "var(--main-color) !important",
    boxShadow: `${alpha(theme.palette.primary.main, 0.3)} 0 0 0 0.2rem`,
  },
  img: {
    height: 26,
  },
}));

const HeaderSeries = React.memo(
  ({
    lectureList,
    title,
    suffix,
    description,
    contextRow,
    mainId,
    lastCourseId,
    newCourseId,
  }) => {
    return (
      <>
        {(lastCourseId || newCourseId) && (
          <Box sx={{ mb: 1, display: "flex", justifyContent: "center" }}>
            <Link to={`/series/${newCourseId}`}>
              <CustomBox isSelected={lastCourseId !== null}>
                <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                  {dic.newCourse(mainId)}
                </Typography>
              </CustomBox>
            </Link>
            <Link to={`/series/${lastCourseId}`}>
              <CustomBox isSelected={newCourseId !== null}>
                <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                  {dic.lastCourse(mainId)}
                </Typography>
              </CustomBox>
            </Link>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            minHeight: 80,
            bgcolor: "primary.main",
            borderRadius: 4,
            borderColor: "text.primary",
            border: 2,
            mb: 1,
            px: 3,
            pt: 1.5,
          }}
        >
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {mainId === 1 && (
                <>
                  <Typography
                    color="black"
                    sx={{ fontWeight: "bold" }}
                    variant="h5"
                  >
                    {contextRow}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    sx={{
                      minHeight: { xs: 13, md: 20, sm: 13, lg: 20, xl: 20 },
                      mx: 1,
                      border: 1,
                    }}
                  />
                </>
              )}
              <Typography
                color="black"
                sx={{ fontWeight: "bold" }}
                variant="h5"
              >
                {suffix ? `${suffix}، ${title}` : title}
              </Typography>
            </Box>
            <Typography sx={{ mt: 0.5, color: "white" }}>
              {lectureList.length} {dic.lecture}
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Collapse in={description !== null}>
              <Typography sx={{ color: "white" }}>{description}</Typography>
            </Collapse>
          </Box>
        </Box>
      </>
    );
  }
);

HeaderSeries.displayName = "HeaderSeries";

const SeriesPage = ({ id }) => {
  const [data, setData] = React.useState(null);
  const [state, setState] = React.useState("lectures");

  const matches = useMediaQuery("(max-width:480px)");

  const handleState = (_event, newState) => {
    setState(newState);
  };

  React.useEffect(() => {
    const fetch = async () => {
      const d = await getContentBySeriesIdAsync({ seriesId: id });
      setData(d?.data);
    };
    if (id) fetch();
  }, [setData, id]);

  return (
    <BaseLayout
      links={
        data ? [{ title: title[data?.mainId], link: link[data?.mainId] }] : []
      }
      pageName={data?.suffix ? `${data?.suffix}، ${data?.title}` : data?.title}
      topFrame={data && <HeaderSeries {...data} />}
    >
      {!matches && (
        <Box sx={{ display: "flex", minHeight: 100, p: 2 }}>
          <Box sx={{ flex: 1, px: 2 }}>
            {data && <HeaderSeries {...data} />}
            {data?.mainId === 1 && <Quran seriesId={id} />}
          </Box>
          <PageDivider />
          <Box sx={{ flex: 1 }}>
            {data && <LectureList data={data} />}
            {state === "lectures" && data?.isNotFinished && (
              <Box
                sx={{
                  mt: 2,
                  bgcolor: "primary.main",
                  borderRadius: 4,
                  borderColor: "text.primary",
                  border: 2,
                  py: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" component={"p"}>
                  {dic.isNotFinished()}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {matches && (
        <Box sx={{ p: 2, pb: 8 }}>
          {data?.mainId === 1 && (
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <ToggleButtonGroup
                value={state}
                exclusive
                onChange={handleState}
                aria-label="text state"
              >
                <CustomToggleButton value={"lectures"}>
                  <Typography component={"div"} sx={{ mr: 1 }}>
                    {dic.lectures}
                  </Typography>
                </CustomToggleButton>
                <CustomToggleButton value={"text"}>
                  <Typography component={"div"} sx={{ mr: 1 }}>
                    {dic.quranText}
                  </Typography>
                </CustomToggleButton>
              </ToggleButtonGroup>
            </Box>
          )}
          {state === "text" && <Quran seriesId={id} />}
          {data && state === "lectures" && <LectureList data={data} />}
          {state === "lectures" && data?.isNotFinished && (
            <Box
              sx={{
                mt: 2,
                bgcolor: "primary.main",
                borderRadius: 4,
                borderColor: "text.primary",
                border: 2,
                py: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" component={"p"}>
                {dic.isNotFinished()}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </BaseLayout>
  );
};

export default SeriesPage;
