import { ButtonBase, Typography, Box } from "@mui/material";
import { navigate } from "@reach/router";
import dic from "~/data/dic";

const AdvancedSearch = () => {
  return (
    <ButtonBase
      onClick={()=>navigate("/search")}
      sx={{
        borderRadius: 4,
        borderColor: "var(--border-color)",
        border: {
          xs: 2,
          lg: 3,
          md: 3,
          sm: 3,
          xl:3,
        },
        height: 38,
        width: 1 / 1,
        p: 0,
      }}
    >
      <Typography
        sx={{
          fontWeight: {
            md: "bold",
            lg: "bold",
            xl: "bold",
            sm: "normal",
          },
          fontSize: {
            xs: 10,
            lg: 14,
            xl: 14,
            md: 14,
            sm: 14,
          },
          lineHeight: 1.2,
        }}
        variant="subtitle2"
      >
        {dic.advanceSearch}
      </Typography>
      <Box
        sx={{ maxWidth: 25, mx: 1 }}
        component={"img"}
        src="/icons/advancedSearch.svg"
      />
    </ButtonBase>
  );
};

export default AdvancedSearch;
