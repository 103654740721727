const IR_BASE_URL = "https://core.monibapp.ir/";
const BASE_URL = "https://core.monibapp.com/";

const isCom = () => {
  const url = window.location.host;
  return url.indexOf("monibapp.com") > -1;
};

const url = () => {
  const url = isCom() ? BASE_URL : IR_BASE_URL;

  return {
    security: `${url}security/v2/`,
    playlist: `${url}user/playlist/`,
    search: `${url}search/`,
    core: `${url}app/v2/`,
    assets: `${url}v2/assets/`,
  };
};

export default url();
