import { Box, Typography } from "@mui/material";

const Header = ({
  title,
  icon,
  border,
  fullWidth,
  children,
  center,
  light,
}) => {
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        minWidth: 80,
        display: fullWidth ? "flex" : "inline-flex",
        borderRadius: 4,
        ...(center && { justifyContent: "center" }),
        ...(light && { color: "white" }),
        bgcolor: "primary.main",
        ...(border && {
          border: 2,
          borderRadius: 4,
          borderColor: "var(--border-color)",
        }),
      }}
    >
      {title && (
        <Typography sx={{ fontWeight: "bold", mr: 1 }}>{title}</Typography>
      )}
      {icon && <Box component={"img"} src={icon} sx={{ width: 20 }} />}
      {children}
    </Box>
  );
};

export default Header;
