import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { reduxBatch } from "@manaflair/redux-batch";
import { rootReducer } from "./rootReducer";
//import { persistStore } from "redux-persist";

const sagaMiddleware = createSagaMiddleware();

// const middleware = [
//   ...getDefaultMiddleware({
//     immutableCheck: false,
//     serializableCheck: false,
//     thunk: true,
//   }),
//   sagaMiddleware,
// ];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }).concat(sagaMiddleware),  
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [reduxBatch],
});


// export const persistor = persistStore(store);
// sagaMiddleware.run(rootSaga);


export default store;
